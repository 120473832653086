<template>
  <page-title pageTitle="Biller" title="View Biller" />
  <biller-form v-if="biller" :biller="biller" />
  <div v-else class="card">
    <LoadingModal v-if="loading" />
    <div v-else-if="error" class="card-body">
      {{ error }}
    </div>
    <div v-else class="card-body">The biller doesn't exist</div>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/shared/PageTitle";
import BillerFormComponent from "@/components/biller/BillerForm";
import LoadingModalComponent from "@/components/utils/LoadingModal";

export default {
  name: "BillerView",
  components: {
    PageTitle: PageTitleComponent,
    BillerForm: BillerFormComponent,
    LoadingModal: LoadingModalComponent,
  },
  data() {
    return {
      id: -1,
      biller: null,
    };
  },
  computed: {
    error() {
      return this.$store.getters.billersError;
    },
    loading() {
      return this.$store.getters.billersLoading;
    },
  },
  async created() {
    this.id = parseInt(this.$route.params.id);
    await this.$store.dispatch("getBillers");
    this.biller = this.$store.getters.getBillerById(this.id);
  },
};
</script>

<style scoped></style>
