<template>
  <page-title pageTitle="Add Item" :title="productGroupName" />
  <product-group-item-form
    :productGroup="{}"
    :submitFunction="addProductGroupItem"
  />
</template>

<script>
import PageTitleComponent from "@/components/shared/PageTitle";
import ProductGroupItemFormComponent from "@/components/productGroup/ProductGroupItemForm";

export default {
  name: "AddProductGroupItemView",
  components: {
    PageTitle: PageTitleComponent,
    ProductGroupItemForm: ProductGroupItemFormComponent,
  },
  data() {
    return {
      id: -1,
      productGroupItem: null,
    };
  },
  computed: {
    productGroupName() {
      if (this.$store.getters.getProductGroupByID(this.id)) {
        return this.$store.getters.getProductGroupByID(this.id).name;
      }
      return "Product Price Group";
    },
  },
  methods: {
    async addProductGroupItem(productGroupItem) {
      await this.$store.dispatch("addProductGroupItem", productGroupItem);
      this.$router.push(`/productGroups/${this.id}`);
    },
  },
  async created() {
    this.id = parseInt(this.$route.params.id);
    let filter = {
      resultsPerPage: 1,
    };
    await this.$store.dispatch("getProductGroups", filter);
  },
};
</script>

<style scoped></style>
