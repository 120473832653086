<template>
  <div class="d-flex justify-content-start align-items-center m-4">
    <div class="form-check form-switch form-check-right">
      <input
        class="form-check-input"
        type="checkbox"
        role="switch"
        id="flexSwitchCheckRightDisabled"
        checked=""
      />
      <h6
        class="card-title mb-0 mx-2 form-check-label"
        for="flexSwitchCheckRightDisabled"
      >
        {{ settingName }}
      </h6>
    </div>
  </div>
</template>

<script>
export default {
  name: "switchSetting",
  props: {
    settingName: {
      type: String,
      required: true,
    },
    inputName: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped></style>
