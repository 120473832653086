<template>
  <div class="autoComplete_wrapper" role="combobox" @focusout="onFocusOut">
    <div :class="{ 'search-box': icon !== '' }">
      <input
        :type="type"
        class="form-control"
        :placeholder="placeholder"
        @focusin="onFocusIn"
        v-model="value"
        @input="emitValue()"
        :disabled="disabled"
      />
      <i v-if="icon !== ''" :class="icon"></i>
    </div>
    <ul :hidden="isHidden">
      <li
        @click="makeChoice(option.name)"
        :class="{ 'bg-soft-primary': value === option.name }"
        v-for="option in options"
        :key="option.name"
      >
        {{ option.name }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "DropdownInput",
  props: {
    chosen: {
      default: "",
    },
    type: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
  },
  methods: {
    onFocusOut() {
      setTimeout(() => {
        this.isHidden = true;
      }, 500);
    },
    onFocusIn() {
      this.isHidden = false;
      this.emitValue();
    },
    emitValue() {
      this.$emit("choice", this.value);
    },
    makeChoice(option) {
      this.value = option;
      this.$emit("choice", this.value);
    },
  },
  data() {
    return {
      isHidden: true,
      value: this.chosen.value,
    };
  },
};
</script>

<style scoped></style>
