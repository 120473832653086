<template>
  <page-title-component pageTitle="Barcodes" title="Print Barcode/Label" />
  <div class="card">
    <div class="card-body border-bottom p-4 d-print-none">
      <div class="row mb-4">
        <label for="style">Add Product</label>
        <div class="col-sm-auto col-md">
          <AutoCompleteInput
            type="text"
            placeholder="Search for Product"
            :chosen="productToAdd.value"
            :options="products"
            :key="reload"
            icon="las la-barcode search-icon"
            @choice="setProductID"
          />
        </div>
        <div class="col-auto input-step" bis_skin_checked="1">
          <button type="button" class="minus" @click="quantityToAdd--">
            –
          </button>
          <input
            type="number"
            class="product-quantity"
            v-model="quantityToAdd"
          />
          <button type="button" class="plus" @click="quantityToAdd++">+</button>
        </div>
        <div class="col-auto">
          <button
            type="button"
            @click="addBarcodeItem()"
            class="btn btn-primary addtax-modal"
          >
            <i class="las la-plus me-1"></i> Add Item
          </button>
        </div>
      </div>
      <div class="table-responsive overflow-auto mb-4" bis_skin_checked="1">
        <table class="invoice-table table table-borderless table-nowrap mb-0">
          <thead class="align-middle">
            <tr class="table-active">
              <th scope="col" v-for="(header, key) in headers" :key="key">
                {{ header }}
              </th>
              <th scope="col" style="width: 12%">Action</th>
            </tr>
          </thead>
          <tbody class="align-middle">
            <tr class="product" v-for="(row, index) in barcodes" :key="index">
              <td>
                {{ row.productName }}
              </td>
              <td>
                <input
                  type="text"
                  class="form-control-plaintext"
                  v-model="row.barcode"
                  readonly
                />
              </td>
              <td>
                <div class="input-step" bis_skin_checked="1">
                  <button
                    type="button"
                    class="minus"
                    @click="
                      row.quantity--;
                      updateBarcode(row);
                    "
                  >
                    –
                  </button>
                  <input
                    type="number"
                    class="product-quantity"
                    v-model="row.quantity"
                    @blur="updateBarcode(row)"
                  />
                  <button
                    type="button"
                    class="plus"
                    @click="
                      row.quantity++;
                      updateBarcode(row);
                    "
                  >
                    +
                  </button>
                </div>
              </td>
              <td>
                <button
                  type="button"
                  @click="deleteBarcodeItem(row.id)"
                  class="btn btn-danger btn-icon"
                >
                  <i class="ri-delete-bin-5-line"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row mb-4">
        <label for="style">Style *</label>
        <div class="input-light">
          <select
            class="form-control bg-light border-0"
            data-choices
            data-choices-search-false
            id="style"
            disabled
          >
            <option value="">Select Style</option>
            <option value="100">100 per sheet (1.5x5)</option>
            <option value="120">120 per sheet (1.25x5)</option>
            <option value="140">140 per sheet (1.25x5)</option>
            <option value="114" selected="selected">
              114 per sheet (1.25x5)
            </option>
            <option value="102">102 per sheet (1.25x5)</option>
            <option value="40">40 per sheet (a4) (1.799" x 1.003")</option>
            <option value="30">30 per sheet (2.625" x 1")</option>
            <option value="24">24 per sheet (a4) (2.48" x 1.334")</option>
            <option value="20">20 per sheet (4" x 1")</option>
            <option value="18">18 per sheet (a4) (2.5" x 1.835")</option>
            <option value="14">14 per sheet (4" x 1.33")</option>
            <option value="12">12 per sheet (a4) (2.5" x 2.834")</option>
            <option value="10">10 per sheet (4" x 2")</option>
            <option value="50">Continuous feed</option>
          </select>
        </div>
      </div>
      <div
        v-for="(value, key) in checkboxes"
        class="form-check form-check-inline mb-2"
        :key="key"
      >
        <input
          class="form-check-input"
          v-model="checkboxes[key]"
          type="checkbox"
          :id="key"
        />
        <label class="form-check-label" :for="key"> {{ key }} </label>
      </div>

      <div class="hstack gap-2 justify-content-end mt-4" bis_skin_checked="1">
        <button
          @click="printPageAsPDF()"
          class="btn btn-primary d-print-none"
          :disabled="barcodes.length === 0"
        >
          <i class="ri-printer-cloud-line align-bottom me-1" />
          Print
        </button>
        <button
          @click="deleteAllBarcodeItems()"
          class="btn btn-danger"
          :disabled="barcodes.length === 0"
        >
          Reset
        </button>
      </div>
    </div>
    <div class="card-body">
      <p v-if="barcodes.length === 0" class="text-danger">
        Add barcodes above, before you try to print*
      </p>
      <div
        v-else
        v-for="(page, index) in transformedBarcodes"
        class="barcodea114"
        :class="{
          'page-break': index !== transformedBarcodes.length - 1,
        }"
        :key="index"
        bis_skin_checked="1"
      >
        <div
          v-for="(row, index) in page"
          class="item style114"
          bis_skin_checked="1"
          :key="index"
        >
          <div class="barcode-item">
            <span v-if="checkboxes['Company Name']" class="barcode_site">
              STAR NOVELTY INC
            </span>
            <span v-if="checkboxes['Product Name 1']" class="barcode_name">
              {{ row.productName1 }}
            </span>
            <span
              v-if="checkboxes['Product Name 2'] && row.productName2 !== ''"
              class="barcode_name"
            >
              {{ row.productName2 }}
            </span>
            <span
              v-if="checkboxes['Suggested Retail Price']"
              class="barcode_price"
            >
              ${{ row.suggestedRetailPrice }}
            </span>
            <span v-if="checkboxes.Barcode" class="barcode_image">
              <img
                width="115"
                height="32"
                :src="`https://www.starnovelty.us/images/barcodes/${row.barcode}.png`"
                :alt="row.barcode"
                class="bcimg"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/shared/PageTitle";
import AutoCompleteInput from "@/components/utils/AutoCompleteInput";
import { SETTINGS } from "@/constants/config";

export default {
  name: "BarcodeView",
  components: { PageTitleComponent, AutoCompleteInput },
  data() {
    return {
      reload: 0,
      productToAdd: {},
      quantityToAdd: 1,
      showEditItemPriceModal: false,
      checkboxes: {
        "Company Name": false,
        "Product Name 1": true,
        "Product Name 2": true,
        Barcode: true,
        "Suggested Retail Price": true,
      },
    };
  },
  methods: {
    printPageAsPDF() {
      window.print();
    },
    async addBarcodeItem() {
      const barcode = {
        productID: this.productToAdd.productID,
        quantity: this.quantityToAdd,
      };
      await this.$store.dispatch("insertBarcode", barcode);
      this.productToAdd = {};
      this.quantityToAdd = 1;
      this.reload++;
      await this.$store.dispatch("getBarcodeItems");
    },
    async updateBarcode(row) {
      const barcode = {
        id: row.id,
        quantity: row.quantity,
      };
      await this.$store.dispatch("updateBarcode", barcode);
      await this.$store.dispatch("getBarcodeItems");
    },
    async deleteBarcodeItem(id) {
      await this.$store.dispatch("deleteBarcodeItem", id);
      await this.$store.dispatch("getBarcodeItems");
    },
    async deleteAllBarcodeItems() {
      await this.$store.dispatch("deleteAllBarcodeItems");
      await this.$store.dispatch("getBarcodeItems");
    },
    async setProductID(value, id) {
      let filter = {
        resultsPerPage: SETTINGS.resultsPerInput,
        searchQuery: value,
      };
      await this.$store.dispatch("getProducts", filter);
      this.productToAdd.value = value;
      this.productToAdd.productID = id;
    },
    async showPopup(id) {
      await this.$store.dispatch("getProductHistory", id);
      this.showEditItemPriceModal = true;
    },
    hidePopup() {
      this.showEditItemPriceModal = false;
    },
  },
  computed: {
    headers() {
      return {
        name: "Name",
        barcode: "Barcode",
        category: "Quantity",
      };
    },
    barcodes() {
      return this.$store.getters.barcodes;
    },
    transformedBarcodes() {
      const transformedData = [];
      let internalArray = [];

      this.barcodes.forEach((item) => {
        let quantity = item.quantity >= 1 ? item.quantity : 0;

        for (let i = 0; i < quantity; i++) {
          const duplicateItem = { ...item };
          duplicateItem.productName1 = duplicateItem.productName.substring(
            0,
            21
          );
          duplicateItem.productName2 = duplicateItem.productName.substring(
            21,
            45
          );

          internalArray.push(duplicateItem);

          if (internalArray.length === 114) {
            transformedData.push(internalArray);
            internalArray = [];
          }
        }
      });

      if (internalArray.length > 0) {
        transformedData.push(internalArray);
      }

      return transformedData;
    },
    products() {
      return this.$store.getters.allProducts;
    },
  },
  async created() {
    await this.$store.dispatch("getBarcodeItems");
  },
};
</script>

<style scoped>
.barcodea114 {
  width: 8.34in;
  height: 10.9in;
  display: block;
  border: 0px solid #ccc;
  margin: 0px 134.635px 0px 0px;
  padding-top: 0.27in;
  padding-bottom: 0.2in;
  padding-left: 0.073in;
  padding-right: 0.05in;
}
.barcodea114 .item {
  display: block;
  overflow: hidden;
  text-align: center;
  border: 0px dotted #ccc;
  font-size: 9px;
  text-transform: uppercase;
  float: left;
}

.barcodea114 .style114 {
  width: 1.333in;
  height: 0.597in;
  margin-top: 0in;
  margin-right: 0in;
  margin-left: 0in;
  margin-bottom: 0in;
  display: block;
  padding-left: 0.03in;
}

.barcodea114 .barcode_site,
.barcodea114 .barcode_name,
.barcodea114 .barcode_image,
.barcodea114 .variants {
  display: block;
}
.barcodea114 .barcode_image {
  width: 125.08;
  height: 29.57;
}
.barcodea114 .barcode_price,
.barcodea114 .barcode_unit,
.barcodea114 .barcode_category {
  display: inline-block;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  float: right;
  margin-top: 30px;
  margin-left: 40px;
  font-weight: bold;
  font-size: 14px;
  position: absolute;
  z-index: 90;
  padding-left: 3px;
  padding-right: 3px;
}

.barcodea114 .bcimg {
  width: 106.31px;
  height: 29.57px;
  display: block;
}

.barcodea114 .product_image {
  width: 0.8in;
  float: left;
  margin: 5px;
}
.barcodea114 .style114 .product_image {
  width: 100%;
  height: auto;
  max-height: 1.5in;
  display: block;
}
.barcodea114 .style114 .product_image img {
  max-width: 100%;
  max-height: 100%;
}
.barcodea114 .style114 .barcode_site,
.barcodea114 .style114 .barcode_name {
  font-size: 8px;
  font-weight: bold;
  text-align: left;
  padding-left: 1.9px;
}
.page-break {
  page-break-after: always;
}
</style>
