import { createRouter, createWebHistory } from "vue-router";
import DashboardView from "@/views/DashboardView";
import NewInvoiceView from "@/views/invoice/NewInvoiceView";
import AllInvoicesView from "@/views/invoice/AllInvoicesView";
import EditInvoiceView from "@/views/invoice/EditInvoiceView";
import InvoiceView from "@/views/invoice/InvoiceView";
import BarcodeView from "@/views/product/BarcodeView";
import EditProductView from "@/views/product/EditProductView";
import ProductView from "@/views/product/ProductView";
import NewProductView from "@/views/product/NewProductView";
import AllProductsView from "@/views/product/AllProductsView";
import SignInView from "@/views/authentication/SignInView";
import ChangePasswordView from "@/views/authentication/ChangePasswordView";
import AllPriceGroupsView from "@/views/priceGroup/AllPriceGroupsView";
import NewPriceGroupView from "@/views/priceGroup/NewPriceGroupView";
import EditPriceGroupView from "@/views/priceGroup/EditPriceGroupView";
import PriceGroupItemsView from "@/views/priceGroup/PriceGroupItemsView";
import AddPriceGroupItemView from "@/views/priceGroup/AddPriceGroupItemView";
import EditPriceGroupItemView from "@/views/priceGroup/EditPriceGroupItemView";
import AllBillersView from "@/views/biller/AllBillersView";
import NewBillerView from "@/views/biller/NewBillerView";
import BillerView from "@/views/biller/BillerView";
import EditBillerView from "@/views/biller/EditBillerView";
import AllBuyersView from "@/views/buyer/AllBuyersView";
import NewBuyerView from "@/views/buyer/NewBuyerView";
import BuyerView from "@/views/buyer/BuyerView";
import EditBuyerView from "@/views/buyer/EditBuyerView";
import ProductReportView from "@/views/report/ProductReportView";
import CustomerReportView from "@/views/report/CustomerReportView";
import CustomerSummaryReportView from "@/views/report/CustomerSummaryReportView";
import BillerReportView from "@/views/report/BillerReportView";
import store from "@/store";
import AllUsersView from "@/views/users/AllUsersView";
import EditUserView from "@/views/users/EditUserView";
import NewUserView from "@/views/users/NewUserView";
import UserView from "@/views/users/UserView";
import PaymentHistoryView from "@/views/payment/PaymentHistoryView";
import DeletedProductsView from "@/views/product/DeletedProductsView";
import DeletedBuyersView from "@/views/buyer/DeletedBuyersView";
import DeletedBillersView from "@/views/biller/DeletedBillersView";
import InvoicePrintView from "@/views/invoice/InvoicePrintView";
import DamagedGoodsReportView from "@/views/report/DamagedGoodsReportView";
import AllExpensesView from "@/views/expense/AllExpensesView";
import EditExpenseView from "@/views/expense/EditExpenseView";
import ExpenseView from "@/views/expense/ExpenseView";
import NewExpenseView from "@/views/expense/NewExpenseView";
import AllProductGroupsView from "@/views/productGroup/AllProductGroupsView";
import NewProductGroupView from "@/views/productGroup/NewProductGroupView";
import EditProductGroupView from "@/views/productGroup/EditProductGroupView";
import ProductGroupItemsView from "@/views/productGroup/ProductGroupItemsView";
import AddProductGroupItemView from "@/views/productGroup/AddProductGroupItemView";
import InvoiceThermalPrintView from "@/views/invoice/InvoiceThermalPrintView";
import SettingsView from "@/views/SettingsView";
import EditCustomerProductGroupView from "@/views/priceGroup/EditCustomerProductGroupView";

const authenticationRoutes = [
  {
    path: "/users",
    name: "allUsers",
    component: AllUsersView,
    meta: {
      title: "All Users",
      requiresAdmin: true,
    },
  },
  {
    path: "/users/edit/:id",
    name: "editUser",
    component: EditUserView,
    meta: {
      title: "Edit User",
      requiresAdmin: true,
    },
  },
  {
    path: "/users/new/",
    name: "newUser",
    component: NewUserView,
    meta: {
      title: "Add New User",
      requiresAdmin: true,
    },
  },
  {
    path: "/users/:id/",
    name: "user",
    component: UserView,
    meta: {
      title: "User",
      requiresAdmin: true,
    },
  },
];

const userRoutes = [
  {
    path: "/signIn",
    name: "signIn",
    component: SignInView,
    meta: {
      title: "SignIn",
      signin: true,
    },
  },
  {
    path: "/changePassword",
    name: "changePassword",
    component: ChangePasswordView,
    meta: {
      title: "Change Password",
    },
  },
];

const priceGroupRoutes = [
  {
    path: "/priceGroups",
    name: "allPriceGroups",
    component: AllPriceGroupsView,
    meta: {
      title: "All Customer Price Groups",
      requiresAuth: true,
    },
  },
  {
    path: "/priceGroups/new",
    name: "newPriceGroup",
    component: NewPriceGroupView,
    meta: {
      title: "Add New Customer Price Group",
      requiresAuth: true,
    },
  },
  {
    path: "/priceGroups/edit/:id",
    name: "EditPriceGroup",
    component: EditPriceGroupView,
    meta: {
      title: "Edit To Customer Price Group",
      requiresAuth: true,
    },
  },
  {
    path: "/priceGroups/edit/productGroup/:id/",
    name: "EditCustomerProductGroup",
    component: EditCustomerProductGroupView,
    meta: {
      title: "Edit Customer Product Group",
      requiresAuth: true,
    },
  },
  {
    path: "/priceGroups/:id",
    name: "priceGroup",
    component: PriceGroupItemsView,
    meta: {
      title: "Customer Price Group",
      requiresAuth: true,
    },
  },
  {
    path: "/priceGroups/:id/add",
    name: "addPriceGroupItem",
    component: AddPriceGroupItemView,
    meta: {
      title: "Add To Customer Price Group",
      requiresAuth: true,
    },
  },
  {
    path: "/priceGroups/:id/edit/:itemID",
    name: "editPriceGroupItem",
    component: EditPriceGroupItemView,
    meta: {
      title: "Edit Customer Price Group Item",
      requiresAuth: true,
    },
  },
];

const productGroupRoutes = [
  {
    path: "/productGroups",
    name: "allProductGroups",
    component: AllProductGroupsView,
    meta: {
      title: "All Product Price Groups",
      requiresAuth: true,
    },
  },
  {
    path: "/productGroups/new",
    name: "newProductGroup",
    component: NewProductGroupView,
    meta: {
      title: "Add New Product Price Group",
      requiresAuth: true,
    },
  },
  {
    path: "/productGroups/edit/:id",
    name: "EditProductGroup",
    component: EditProductGroupView,
    meta: {
      title: "Edit To Product Price Group",
      requiresAuth: true,
    },
  },
  {
    path: "/productGroups/:id",
    name: "productGroup",
    component: ProductGroupItemsView,
    meta: {
      title: "Product Price Group",
      requiresAuth: true,
    },
  },
  {
    path: "/productGroups/:id/add",
    name: "addProductGroupItem",
    component: AddProductGroupItemView,
    meta: {
      title: "Add To Product Price Group",
      requiresAuth: true,
    },
  },
];

const billerRoutes = [
  {
    path: "/billers",
    name: "allBillers",
    component: AllBillersView,
    meta: {
      title: "All Billers",
      requiresAuth: true,
    },
  },
  {
    path: "/billers/recover",
    name: "hiddenBiller",
    component: DeletedBillersView,
    meta: {
      title: "All Hidden Biller",
      requiresAuth: true,
    },
  },
  {
    path: "/billers/new",
    name: "newBiller",
    component: NewBillerView,
    meta: {
      title: "Add New Biller",
      requiresAuth: true,
    },
  },
  {
    path: "/billers/:id",
    name: "biller",
    component: BillerView,
    meta: {
      title: "Biller",
      requiresAuth: true,
    },
  },
  {
    path: "/billers/edit/:id",
    name: "editBiller",
    component: EditBillerView,
    meta: {
      title: "Edit Biller",
      requiresAuth: true,
    },
  },
];

const buyerRoutes = [
  {
    path: "/customers",
    name: "allcustomers",
    component: AllBuyersView,
    meta: {
      title: "All Customer",
      requiresAuth: true,
    },
  },
  {
    path: "/customers/recover",
    name: "hiddenCustomers",
    component: DeletedBuyersView,
    meta: {
      title: "All Hidden Customers",
      requiresAuth: true,
    },
  },
  {
    path: "/customers/new",
    name: "newCustomer",
    component: NewBuyerView,
    meta: {
      title: "Add New Customer",
      requiresAuth: true,
    },
  },
  {
    path: "/customers/:id",
    name: "customer",
    component: BuyerView,
    meta: {
      title: "Customer",
      requiresAuth: true,
    },
  },
  {
    path: "/customers/edit/:id",
    name: "editCustomer",
    component: EditBuyerView,
    meta: {
      title: "Edit Customer",
      requiresAuth: true,
    },
  },
];

const invoiceRoutes = [
  {
    path: "/invoices",
    name: "allInvoices",
    component: AllInvoicesView,
    meta: {
      title: "All Invoices",
      requiresAuth: true,
    },
  },
  {
    path: "/invoices/new",
    name: "newInvoice",
    component: NewInvoiceView,
    meta: {
      title: "New Invoice",
      requiresAuth: true,
    },
  },
  {
    path: "/invoices/edit/:id",
    name: "editInvoice",
    component: EditInvoiceView,
    meta: {
      title: "Edit Invoice",
      requiresAuth: true,
    },
  },
  {
    path: "/invoices/:id",
    name: "invoice",
    component: InvoiceView,
    meta: {
      title: "Invoice",
      requiresAuth: true,
    },
  },
  {
    path: "/invoices/print/:id",
    name: "invoicePrint",
    component: InvoicePrintView,
    meta: {
      title: "Print Invoice",
      requiresAuth: true,
    },
  },
  {
    path: "/invoices/thermalPrint/:id",
    name: "invoiceThermalPrint",
    component: InvoiceThermalPrintView,
    meta: {
      title: "Print Invoice",
      requiresAuth: true,
    },
  },
];

const paymentRoutes = [
  {
    path: "/payment/customer/:id",
    name: "customerPaymentHistory",
    component: PaymentHistoryView,
    meta: {
      title: "Customer Payment History",
      filterBy: "Customer",
      requiresAuth: true,
    },
  },
  {
    path: "/payment/invoice/:id",
    name: "invoicePaymentHistory",
    component: PaymentHistoryView,
    meta: {
      title: "Invoice Payment History",
      filterBy: "Invoice",
      requiresAuth: true,
    },
  },
];

const expensesRoutes = [
  {
    path: "/expenses",
    name: "allExpensesView",
    component: AllExpensesView,
    meta: {
      title: "All Expenses",
      requiresAuth: true,
    },
  },
  {
    path: "/expenses/new",
    name: "newExpense",
    component: NewExpenseView,
    meta: {
      title: "Add New Expense",
      requiresAuth: true,
    },
  },
  {
    path: "/expenses/:id",
    name: "expense",
    component: ExpenseView,
    meta: {
      title: "Expense",
      requiresAuth: true,
    },
  },
  {
    path: "/expenses/edit/:id",
    name: "editExpense",
    component: EditExpenseView,
    meta: {
      title: "Edit Expense",
      requiresAuth: true,
    },
  },
];

const productRoutes = [
  {
    path: "/products",
    name: "allProducts",
    component: AllProductsView,
    meta: {
      title: "All Products",
      requiresAuth: true,
    },
  },
  {
    path: "/products/recover",
    name: "hiddenProducts",
    component: DeletedProductsView,
    meta: {
      title: "All Hidden Products",
      requiresAuth: true,
    },
  },
  {
    path: "/products/new",
    name: "newProduct",
    component: NewProductView,
    meta: {
      title: "Add New Product",
      requiresAuth: true,
    },
  },
  {
    path: "/products/edit/:id",
    name: "editProduct",
    component: EditProductView,
    meta: {
      title: "Edit Product",
      requiresAuth: true,
    },
  },
  {
    path: "/products/:id",
    name: "product",
    component: ProductView,
    meta: {
      title: "Product",
      requiresAuth: true,
    },
  },
  {
    path: "/products/barcode",
    name: "barcode",
    component: BarcodeView,
    meta: {
      title: "Barcode",
      requiresAuth: true,
    },
  },
];

const reportRoutes = [
  {
    path: "/reports/product",
    name: "productReport",
    component: ProductReportView,
    meta: {
      title: "Product Report",
      requiresAuth: true,
    },
  },
  {
    path: "/reports/customer",
    name: "customerReport",
    component: CustomerReportView,
    meta: {
      title: "My Report",
      requiresAuth: true,
    },
  },
  {
    path: "/reports/customerSummary",
    name: "customerSummaryReport",
    component: CustomerSummaryReportView,
    meta: {
      title: "Customer Summary Report",
      requiresAuth: true,
    },
  },
  {
    path: "/reports/biller",
    name: "billerReport",
    component: BillerReportView,
    meta: {
      title: "Biller Report",
      requiresAuth: true,
    },
  },
  {
    path: "/reports/damagedGoods",
    name: "Damaged Goods Report",
    component: DamagedGoodsReportView,
    meta: {
      title: "Damaged Goods Report",
      requiresAuth: true,
    },
  },
];

const routes = [
  {
    path: "/",
    name: "dashboard",
    component: DashboardView,
    meta: {
      title: "Dashboard",
      requiresAuth: true,
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: SettingsView,
    meta: {
      title: "Settings",
      requiresAuth: true,
    },
  },
  ...authenticationRoutes,
  ...userRoutes,
  ...priceGroupRoutes,
  ...productGroupRoutes,
  ...billerRoutes,
  ...buyerRoutes,
  ...invoiceRoutes,
  ...paymentRoutes,
  ...expensesRoutes,
  ...productRoutes,
  ...reportRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  },
});

// Token access control
router.beforeEach(async (to, from, next) => {
  try {
    await store.dispatch("TokenSignIn");
  } catch (error) {
    console.error(error);
  }
  next();
});

// Authentication access control
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next("/signin");
  } else {
    next();
  }
});

// Sign In Page access control
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.signin)) {
    if (store.getters.isAuthenticated) {
      next("/");
      return;
    }
    next();
  } else {
    next();
  }
});

// Admin pages access control
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAdmin)) {
    if (store.getters.isAdmin) {
      next();
      return;
    } else if (store.getters.isAuthenticated) {
      next("/");
      return;
    }
    next("/signin");
  } else {
    next();
  }
});

// Add the beforeEach navigation guard
router.beforeEach((to, from, next) => {
  // Check if the route has a meta field with the 'title' property
  if (to.meta.title) {
    document.title = `${to.meta.title} | Invoice Management`;
  }
  next(); // Continue with the navigation
});

export default router;
