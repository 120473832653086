<template>
  <page-title pageTitle="User" title="Edit User" action-type="edit" />
  <user-form
    v-if="!loading"
    :user="user"
    :submitFunction="editUser"
    action-type="edit"
  />
  <div v-else className="card">
    <LoadingModal v-if="loading" />
    <div v-else-if="error" className="card-body">
      {{ error }}
    </div>
    <div v-else className="card-body">The user doesn't exist</div>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/shared/PageTitle";
import UserFormComponent from "@/components/users/UserForm";
import LoadingModalComponent from "@/components/utils/LoadingModal";

export default {
  name: "EditUserView",
  components: {
    PageTitle: PageTitleComponent,
    UserForm: UserFormComponent,
    LoadingModal: LoadingModalComponent,
  },
  data() {
    return {
      id: -1,
    };
  },
  computed: {
    user() {
      return this.$store.getters.person;
    },
    loading() {
      return !this.user.id;
    },
  },
  methods: {
    async editUser(user) {
      await this.$store.dispatch("editPerson", user);
      this.$router.push("/users");
    },
  },
  async created() {
    this.id = parseInt(this.$route.params.id);
    await this.$store.dispatch("getPerson", this.id);
  },
};
</script>

<style scoped></style>
