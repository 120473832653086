import axios from "axios";
import { CONFIG } from "@/constants/config.js";

const userBackend = CONFIG.BACKEND_URL + "user";

const state = {
  user: null,
  token: null,
  users: [],
  person: {},
  loading: false,
};

const config = () => ({
  headers: {
    Token: localStorage.getItem("accessToken"),
  },
});

const getters = {
  isAdmin: (state) => state.user && state.user.type === "admin",
  isUser: (state) => state.user && state.user.type === "user",
  isAuthenticated: (state) => state.user,
  StateUser: (state) => state.user,
  allUsers: (state) => state.users,
  person: (state) => state.person,
  userLoading: (state) => state.loading,
};

const actions = {
  async signUp({ commit /*, dispatch */ }, user) {
    commit("setLoading", true);
    await axios
      .post(`${userBackend}/signUp/`, user, config())
      .then(async (response) => {
        console.log(response);
        commit("setLoading", false);
        // dispatch(
        //   "notification/AddNotification",
        //   { type: "success", message: "New User Successfully Created" },
        //   { root: true }
        // );
      })
      .catch((error) => {
        if (error.response.data.message) {
          error.message = error.response.data.message;
        }
        commit("setLoading", false);
        // dispatch(
        //   "notification/AddNotification",
        //   { type: "error", message: error.message },
        //   { root: true }
        // );
        throw error;
      });
  },
  async SignIn({ commit /*, dispatch */ }, user) {
    commit("setLoading", true);
    await axios
      .post(`${userBackend}/signIn/`, user)
      .then(async (response) => {
        await commit("setUser", response.data);
        commit("setLoading", false);
        // dispatch(
        //   "notification/AddNotification",
        //   { type: "success", message: "Successfully Signed In" },
        //   { root: true }
        // );
      })
      .catch((error) => {
        if (error.response.data.message) {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: error.message },
          //   { root: true }
          // );
        }
        commit("setLoading", false);
        throw error;
      });
  },
  async TokenSignIn({ commit }) {
    commit("setLoading", true);
    await axios
      .post(`${userBackend}/token/`, null, config())
      .then(async (response) => await commit("setUser", response.data))
      .catch((error) => {
        commit("setLoading", false);
        if (error.code !== "ERR_NETWORK") {
          commit("signOut");
        }
        // dispatch(
        //   "notification/AddNotification",
        //   { type: "error", message: error.message },
        //   { root: true }
        // );
        throw error;
      });
  },
  async ChangePassword({ commit /*, dispatch*/ }, user) {
    commit("setLoading", true);
    await axios
      .patch(`${userBackend}/changePassword/`, user, config())
      .then(
        async (response) => console.log(response)
        // dispatch(
        //   "notification/AddNotification",
        //   { type: "success", message: "Password Successfully Changed" },
        //   { root: true }
        // )
      )
      .catch((error) => {
        if (error.response.data.message) {
          error.message = error.response.data.message;
        }
        commit("setLoading", false);
        // dispatch(
        //   "notification/AddNotification",
        //   { type: "error", message: error.message },
        //   { root: true }
        // );
        throw error;
      });
  },
  async SignOut({ commit }) {
    commit("setLoading", true);
    await axios
      .post(`${userBackend}/signOut/`, null, config())
      .then(async (response) => {
        console.log(response);
        // dispatch(
        //   "notification/AddNotification",
        //   { type: "success", message: "Successfully Signed Out" },
        //   { root: true }
        // )
      })
      .catch((error) => {
        if (error.response.data.message) {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: error.message },
          //   { root: true }
          // );
        }
        commit("setLoading", false);
      });
    commit("signOut");
  },
  async getPerson({ commit }, id) {
    commit("setLoading", true);
    await axios
      .post(`${userBackend}/${id}/`, null, config())
      .then(async (response) => await commit("setPerson", response.data))
      .catch((error) => {
        if (error.response.data.message) {
          error.message = error.response.data.message;
        }
        commit("setLoading", false);
        // dispatch(
        //   "notification/AddNotification",
        //   { type: "error", message: error.message },
        //   { root: true }
        // );
        throw error;
      });
  },
  async getUsers({ commit }) {
    commit("setLoading", true);
    await axios
      .post(`${userBackend}/all/`, null, config())
      .then(async (response) => await commit("setUsers", response.data))
      .catch((error) => {
        if (error.response.data.message) {
          error.message = error.response.data.message;
        }
        commit("setLoading", false);
        // dispatch(
        //   "notification/AddNotification",
        //   { type: "error", message: error.message },
        //   { root: true }
        // );
        throw error;
      });
  },
  async editPerson({ commit /*, dispatch*/ }, user) {
    commit("setLoading", true);
    await axios
      .patch(`${userBackend}/update/`, user, config())
      .then(
        async (response) => console.log(response)
        // dispatch(
        //   "notification/AddNotification",
        //   { type: "success", message: "Password Successfully Changed" },
        //   { root: true }
        // )
      )
      .catch((error) => {
        if (error.response.data.message) {
          error.message = error.response.data.message;
        }
        commit("setLoading", false);
        // dispatch(
        //   "notification/AddNotification",
        //   { type: "error", message: error.message },
        //   { root: true }
        // );
        throw error;
      });
  },
  async resetPerson({ commit }) {
    await commit("setPerson", {});
  },
};

const mutations = {
  setUser(state, user) {
    state.user = {};
    state.user.user_id = user.id;
    state.user.username = user.username;
    state.user.email = user.email;
    state.user.type = user.type;
    if (user.token) {
      localStorage.setItem("accessToken", user.token);
    }
    state.user.token = localStorage.getItem("accessToken");
  },
  signOut(state) {
    localStorage.removeItem("accessToken");
    state.user = null;
  },
  setUsers(state, users) {
    state.users = users;
  },
  setPerson(state, person) {
    state.person = person;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
