import axios from "axios";
import { CONFIG } from "@/constants/config.js";

const reportsBackend = CONFIG.BACKEND_URL + "reports";

const state = {
  productReport: { loading: false, error: "", productAnalysis: [] },
  buyerReport: { loading: false, error: "", customerAnalysis: [] },
  customerSummaryReport: { loading: false, error: "", customerSummary: [] },
  billerReport: { loading: false, error: "", billerAnalysis: [] },
  damagedGoodsReport: { loading: false, error: "", damagedGoods: [] },
  loading: false,
  error: "",
  topProductsReport: { loading: false, error: "" },
  productsStatusReport: { loading: false, error: "" },
  statusReport: { loading: false, error: "" },
  statusOverviewReport: { loading: false, error: "" },
  invoicesReport: { loading: false, error: "" },
};

const config = () => ({
  headers: {
    Token: localStorage.getItem("accessToken"),
  },
});

const getters = {
  productReport: (state) => state.productReport,
  buyerReport: (state) => state.buyerReport,
  customerSummaryReport: (state) => state.customerSummaryReport,
  billerReport: (state) => state.billerReport,
  damagedGoodsReport: (state) => state.damagedGoodsReport,
  reportsLoading: (state) => state.loading,
  reportsError: (state) => state.error,
  topProductsReport: (state) => state.topProductsReport,
  productsStatusReport: (state) => state.productsStatusReport,
  statusReport: (state) => state.statusReport,
  statusOverviewReport: (state) => state.statusOverviewReport,
  invoicesReport: (state) => state.invoicesReport,
};

const actions = {
  async getProductReport({ commit /*, dispatch */ }, filter = null) {
    commit("setProductReport", { loading: true });
    await axios
      .post(`${reportsBackend}/product/`, filter, config())
      .then(async (response) => {
        await commit("setProductReport", { response: response.data });
      })
      .catch(async (error) => {
        if (error.response) {
          await commit("setProductReport", {
            error: error.response.data.message,
          });
        } else {
          await commit("setProductReport", { error: error });
        }
      });
  },
  async getBuyerReport({ commit /*, dispatch */ }, filter = null) {
    commit("setBuyerReport", { loading: true });
    await axios
      .post(`${reportsBackend}/customer/`, filter, config())
      .then(async (response) => {
        await commit("setBuyerReport", { response: response.data });
      })
      .catch(async (error) => {
        if (error.response) {
          await commit("setBuyerReport", {
            error: error.response.data.message,
          });
        } else {
          await commit("setBuyerReport", { error: error });
        }
      });
  },
  async getCustomerSummaryReport({ commit /*, dispatch */ }, filter = null) {
    await commit("setCustomerSummaryReport", { loading: true });
    await axios
      .post(`${reportsBackend}/customerSummary/`, filter, config())
      .then(async (response) => {
        await commit("setCustomerSummaryReport", { response: response.data });
      })
      .catch(async (error) => {
        if (error.response) {
          await commit("setCustomerSummaryReport", {
            error: error.response.data.message,
          });
        } else {
          await commit("setCustomerSummaryReport", { error: error });
        }
      });
  },
  async getBillerReport({ commit /*, dispatch */ }, filter = null) {
    await commit("setBillerReport", { loading: true });
    await axios
      .post(`${reportsBackend}/biller/`, filter, config())
      .then(async (response) => {
        await commit("setBillerReport", { response: response.data });
      })
      .catch(async (error) => {
        if (error.response) {
          await commit("setBillerReport", {
            error: error.response.data.message,
          });
        } else {
          await commit("setBillerReport", { error: error });
        }
      });
  },
  async getDamagedGoodsReport({ commit /*, dispatch */ }, filter = null) {
    await commit("setDamagedGoodsReport", { loading: true });
    await axios
      .post(`${reportsBackend}/damagedGoods/`, filter, config())
      .then(async (response) => {
        await commit("setDamagedGoodsReport", { response: response.data });
      })
      .catch(async (error) => {
        if (error.response) {
          await commit("setDamagedGoodsReport", {
            error: error.response.data.message,
          });
        } else {
          await commit("setDamagedGoodsReport", { error: error });
        }
      });
  },
  async getTopProductReport({ commit /*, dispatch */ }, filter = null) {
    commit("setTopProductsLoading", { loading: true, error: "" });
    await axios
      .post(`${reportsBackend}/productsTop/`, filter, config())
      .then(async (response) => {
        await commit("setTopProductsReport", response.data);
      })
      .catch(async (error) => {
        if (error.response) {
          commit("setTopProductsLoading", {
            loading: false,
            error: error.response.data.message,
          });
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
          console.log(error);
        }
      });
  },
  async getProductsStatusReport({ commit /*, dispatch */ }, filter = null) {
    commit("setProductsStatusLoading", { loading: true, error: "" });
    await axios
      .post(`${reportsBackend}/productStatus/`, filter, config())
      .then(async (response) => {
        await commit("setProductsStatusReport", response.data);
      })
      .catch(async (error) => {
        if (error.response) {
          commit("setProductsStatusLoading", {
            loading: false,
            error: error.response.data.message,
          });
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
          console.log(error);
        }
      });
  },
  async getStatusReport({ commit /*, dispatch */ }, filter = null) {
    commit("setStatusLoading", { loading: true, error: "" });
    await axios
      .post(`${reportsBackend}/status/`, filter, config())
      .then(async (response) => {
        await commit("setStatusReport", response.data);
      })
      .catch(async (error) => {
        if (error.response) {
          commit("setStatusLoading", {
            loading: false,
            error: error.response.data.message,
          });
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
          console.log(error);
        }
      });
  },
  async getStatusOverviewReport({ commit /*, dispatch */ }, filter = null) {
    commit("setStatusOverviewLoading", { loading: true, error: "" });
    await axios
      .post(`${reportsBackend}/statusOverview/`, filter, config())
      .then(async (response) => {
        await commit("setStatusOverviewReport", response.data);
      })
      .catch(async (error) => {
        if (error.response) {
          commit("setStatusOverviewLoading", {
            loading: false,
            error: error.response.data.message,
          });
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
          console.log(error);
        }
      });
  },
  async getInvoicesReport({ commit /*, dispatch */ }, filter = null) {
    commit("setInvoicesLoading", { loading: true, error: "" });
    await axios
      .post(`${reportsBackend}/invoices/`, filter, config())
      .then(async (response) => {
        await commit("setInvoicesReport", response.data);
      })
      .catch(async (error) => {
        if (error.response) {
          commit("setInvoicesLoading", {
            loading: false,
            error: error.response.data.message,
          });
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
          console.log(error);
        }
      });
  },
};

const mutations = {
  setProductReport(state, { loading = false, error = "", response = null }) {
    state.productReport = {
      ...state.productReport,
      loading,
      error,
      ...(response ? response : {}),
    };
  },
  setBuyerReport(state, { loading = false, error = "", response = null }) {
    state.buyerReport = {
      ...state.buyerReport,
      loading,
      error,
      ...(response ? response : {}),
    };
  },
  setCustomerSummaryReport(
    state,
    { loading = false, error = "", response = null }
  ) {
    state.customerSummaryReport = {
      ...state.customerSummaryReport,
      loading,
      error,
      ...(response ? response : {}),
    };
  },
  setBillerReport(state, { loading = false, error = "", response = null }) {
    state.billerReport = {
      ...state.billerReport,
      loading,
      error,
      ...(response ? response : {}),
    };
  },
  setDamagedGoodsReport(
    state,
    { loading = false, error = "", response = null }
  ) {
    state.damagedGoodsReport = {
      ...state.damagedGoodsReport,
      loading,
      error,
      ...(response ? response : {}),
    };
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setError(state, error) {
    state.error = error;
  },
  setTopProductsReport(state, response) {
    state.topProductsReport = response;
    state.topProductsReport.loading = false;
    state.topProductsReport.error = "";
  },
  setTopProductsLoading(state, { loading, error }) {
    state.topProductsReport.loading = loading;
    state.topProductsReport.error = error;
  },
  setProductsStatusReport(state, response) {
    state.productsStatusReport = response;
    state.productsStatusReport.loading = false;
    state.productsStatusReport.error = "";
  },
  setProductsStatusLoading(state, { loading, error }) {
    state.productsStatusReport.loading = loading;
    state.productsStatusReport.error = error;
  },
  setStatusReport(state, response) {
    state.statusReport = response;
    state.statusReport.loading = false;
    state.statusReport.error = "";
  },
  setStatusLoading(state, { loading, error }) {
    state.statusReport.loading = loading;
    state.statusReport.error = error;
  },
  setStatusOverviewReport(state, response) {
    state.statusOverviewReport = response;
    state.statusOverviewReport.loading = false;
    state.statusOverviewReport.error = "";
  },
  setStatusOverviewLoading(state, { loading, error }) {
    state.statusOverviewReport.loading = loading;
    state.statusOverviewReport.error = error;
  },
  setInvoicesReport(state, response) {
    state.invoicesReport = response;
    state.invoicesReport.loading = false;
    state.invoicesReport.error = "";
  },
  setInvoicesLoading(state, { loading, error }) {
    state.invoicesReport.loading = loading;
    state.invoicesReport.error = error;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
