<template>
  <dialogue-modal
    title="Do you want to print the invoice?"
    :message="invoiceDetails.invoiceNo"
    :buttons="buttons"
  />
  <loading-modal v-if="loading" />
  <div
    v-else
    :id="invoiceDetails.invoiceNo"
    class="justify-content-center bg-white"
    style="margin-top: 25px"
  >
    <div
      v-if="invoiceDetails.billerID === 2 && host.includes('platinum')"
      class="text-center"
      style="margin-bottom: 20px"
      bis_skin_checked="1"
    >
      <img src="/images/logo.jpg" alt="STAR NOVELTY INC (2)" />
      <br />
      12114 Dunbeg Lane Richmond TEXAS 77407 <br />
      (832)618-3060
    </div>
    <div
      v-if="invoiceDetails.billerID === 2 && host.includes('lakejune')"
      class="text-center"
      style="margin-bottom: 20px"
      bis_skin_checked="1"
    >
      <img src="/images/lakejune.png" alt="LAKE JUNE BUSINESS INC" />
      <br />
      11402 Harry Hines Blvd Dallas TEXAS 75229 <br />
      (972)800-2958
    </div>
    <div
      v-else-if="invoiceDetails.billerID === 2 && host.includes('diamond')"
      class="text-center"
      style="margin-bottom: 20px"
      bis_skin_checked="1"
    >
      <img src="/images/diamond_logo.jpg" alt="EZ NOVELTY INC (2)" />
      <br />
      3208 Venezia Vw , Leander TX <br />
      5125764065
    </div>
    <div
      v-else-if="invoiceDetails.billerID === 2 && host.includes('silver')"
      class="text-center"
      style="margin-bottom: 20px"
      bis_skin_checked="1"
    >
      <img src="/images/silver_logo.jpg" alt="S&K Wholesale Inc" />
      <br />
      13715 Glendon dr, Richmond, 77407 TX <br />
      8326056990
    </div>
    <div
      v-else-if="invoiceDetails.billerID === 2 && host.includes('golden')"
      class="text-center"
      style="margin-bottom: 20px"
      bis_skin_checked="1"
    >
      <img src="/images/golden_logo.jpg" alt="Xpert Novelty Inc" />
      <br />
      18238 port dundas drive, Richmond, 77407 TX <br />
      8324195919
    </div>
    <div class="row m-1 mb-2 mt-0" style="font-size: 14px" bis_skin_checked="1">
      <div class="col" bis_skin_checked="1">
        <strong>
          <h3 class="mb-2">
            To:
            {{ invoiceDetails.customer }}
          </h3>
          <h6 class="mb-2">
            <strong> {{ invoiceDetails.fullCustomerAddress }}</strong>
          </h6>
        </strong>
      </div>
    </div>
    <div class="row m-1 mb-2 mt-0" style="font-size: 10px" bis_skin_checked="1">
      <div class="col" bis_skin_checked="1">
        <strong>
          <h5 class="mb-2">
            Date: {{ this.dateFormat(invoiceDetails.date) }}<br />
            <span> Invoice No: </span>
            <br />
            {{ invoiceDetails.invoiceNo.split("Invoice/")[1] }}
            <br />
          </h5>
        </strong>
      </div>
    </div>

    <div class="table-responsive" bis_skin_checked="1">
      <table
        class="table table-hover print-table order-table"
        style="border-color: darkgrey"
      >
        <thead style="border-color: darkgrey">
          <tr>
            <th>No</th>
            <th>Qty</th>
            <th>Description</th>
            <th>Unit Price</th>
            <th>Subtotal</th>
          </tr>
        </thead>

        <tbody>
          <template v-for="(row, index) in data" :key="index">
            <tr>
              <td style="text-align: left; vertical-align: middle">
                {{ index + 1 }}
              </td>
              <td style="text-align: left; vertical-align: middle">
                {{ row.quantity }}
              </td>
              <td style="text-align: left; vertical-align: middle">
                <span style="font-weight: bold"> {{ row.productName }} </span>
              </td>
              <td style="text-align: left">
                {{ priceFormat(row.sellingPrice) }}
              </td>
              <td style="text-align: left">
                {{ priceFormat(row.subtotal) }}
              </td>
            </tr>
            <tr>
              <td colspan="5" style="text-align: left; vertical-align: middle">
                <img
                  width="230"
                  height="60"
                  :src="`/images/barcodes/${row.barcode}.png`"
                  :alt="row.barcode"
                />
              </td>
              <td style="text-align: left; vertical-align: middle">
                Sug Retai {{ priceFormat(row.suggestedRetailPrice) }}
              </td>
            </tr>
          </template>
        </tbody>

        <tfoot style="display: table-row-group">
          <tr v-for="(value, key, index) in invoiceDetailsFooter" :key="index">
            <td colspan="4" style="text-align: right; font-weight: bold">
              {{ key }}
            </td>
            <td style="text-align: right; font-weight: bold">
              {{ value }}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>

    <div class="row" bis_skin_checked="1">
      <div class="col-xs-12" bis_skin_checked="1"></div>
    </div>
    <div v-if="invoiceDetails.notes" class="mt-4">
      <div class="alert alert-info">
        <p class="mb-0">
          <span class="fw-semibold">Notes For Customer: </span>
          <span id="note">
            {{ invoiceDetails.notes }}
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingModal from "@/components/utils/LoadingModal";
import DialogueModal from "@/components/utils/modals/DialogueModal";
import { CONFIG } from "@/constants/config";

export default {
  name: "InvoiceThermalPrintView.vue",
  components: {
    DialogueModal,
    LoadingModal,
  },
  data() {
    return {
      id: -1,
    };
  },
  computed: {
    data() {
      return this.$store.getters.invoice;
    },
    invoiceDetails() {
      return this.$store.getters.invoiceSummary;
    },
    invoiceTotals() {
      return this.$store.getters.invoiceTotals;
    },
    invoiceDetailsFooter() {
      let footer = {
        "Total Amount": this.priceFormat(this.invoiceTotals.total),
        "Total Credit": this.priceFormat(this.invoiceTotals.amountCredited),
        "Redeemed Discount Value": this.priceFormat(
          this.invoiceTotals.discountRedeemed
        ),
        "Redeemed Points Value": "$0.00",
        "Total Paid": this.priceFormat(this.invoiceTotals.paid),
        "Total Balance": this.priceFormat(this.invoiceTotals.balance),
      };
      if (footer["Amount Credited"] === "$0.00") {
        delete footer["Amount Credited"];
      }
      if (footer["Redeemed Discount Value"] === "$0.00") {
        delete footer["Redeemed Discount Value"];
      }
      if (footer["Redeemed Points Value"] === "$0.00") {
        delete footer["Redeemed Points Value"];
      }
      return footer;
    },
    suggestedAmountFooter() {
      if (this.invoiceDetails.showSuggestedAnalysis === 0) {
        return {};
      }
      return {
        "Total Sugg": this.priceFormat(this.invoiceTotals.totalSuggested),
        "Sugg Credit": this.priceFormat(this.invoiceTotals.suggestedCredited),
        Margin: this.percentageFormat(
          parseFloat(this.invoiceTotals.totalSuggestedProfit)
        ),
        "Sugg Balance": this.priceFormat(this.invoiceTotals.suggestedBalance),
      };
    },
    loading() {
      return !this.invoiceDetails;
    },
    buttons() {
      return {
        Back: {
          class: "btn-light",
          function: () => this.$router.push("/invoices"),
        },
        Print: {
          class: "btn-success px-3",
          function: () => window.print(),
        },
      };
    },
    host() {
      return CONFIG.HOST;
    },
  },
  methods: {
    dateFormat(date) {
      let parts = date.split("-");
      let formattedDate = parts[1] + "-" + parts[2] + "-" + parts[0];
      return formattedDate;
    },
    priceFormat(price) {
      return `$${price
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`.replace("$-", "-$");
    },
    percentageFormat(value) {
      return value.toFixed(2) + "%";
    },
  },
  async created() {
    this.id = parseInt(this.$route.params.id);
    await this.$store.dispatch("getInvoice", this.id);
    await this.$store.dispatch("getInvoiceItems", {
      id: this.id,
      filter: null,
    });
    window.print();
    // window.onafterprint = () => {
    //   this.$router.go(-1);
    //   window.onafterprint = null;
    // };
  },
  async beforeUnmount() {
    await this.$store.dispatch("resetInvoice");
    await this.$store.dispatch("resetBiller");
    await this.$store.dispatch("resetCustomer");
  },
};
</script>

<style scoped>
@media print {
  table {
    font-size: 20pt;
    font-weight: bold;
  }
  thead {
    font-size: 50pt;
    font-weight: bold;
  }
}

@page {
  margin: 0 !important;
  margin-right: 10px !important;
}
</style>
