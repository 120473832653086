<template>
  <page-title
    pageTitle="Product Price Group"
    title="Edit Product Price Group"
  />
  <product-group-form
    v-if="productGroup"
    :productGroup="productGroup"
    :submitFunction="editProductGroup"
  />
  <div v-else class="card">
    <LoadingModal v-if="loading" />
    <div v-else-if="error" class="card-body">
      {{ error }}
    </div>
    <div v-else class="card-body">The Product Price Group doesn't exist</div>
    <div class="gap-2 mt-4 card-body">
      <button type="button" class="btn btn-light" @click="this.$router.go(-1)">
        Back
      </button>
    </div>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/shared/PageTitle";
import ProductGroupFormComponent from "@/components/productGroup/ProductGroupForm";
import LoadingModalComponent from "@/components/utils/LoadingModal";

export default {
  name: "EditProductGroupView",
  components: {
    PageTitle: PageTitleComponent,
    ProductGroupForm: ProductGroupFormComponent,
    LoadingModal: LoadingModalComponent,
  },
  data() {
    return {
      id: -1,
      productGroup: null,
    };
  },
  computed: {
    error() {
      return this.$store.getters.productGroupsError;
    },
    loading() {
      return this.$store.getters.productGroupsLoading;
    },
  },
  methods: {
    async editProductGroup(productGroup) {
      await this.$store.dispatch("editProductGroup", productGroup);
      this.$router.push("/productGroups");
    },
  },
  async created() {
    this.id = parseInt(this.$route.params.id);
    await this.$store.dispatch("getProductGroups");
    this.productGroup = this.$store.getters.getProductGroupByID(this.id);
  },
};
</script>

<style scoped></style>
