<template>
  <page-title pageTitle="Users" title="All Users" />

  <div class="row pb-4 gy-3">
    <div class="col-sm-4">
      <router-link to="/users/new" class="btn btn-primary addtax-modal">
        <i class="las la-plus me-1"></i> Add New
      </router-link>
    </div>
  </div>

  <div v-if="data && data.length > 0">
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <table-component
              :headers="headers"
              :data="data"
              :actions="actions"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row align-items-center mb-4 gy-3">
      <div class="col-md-5">
        <p class="mb-0 text-muted">
          Showing <b>{{ data.length }}</b> of <b>{{ data.length }}</b> results
        </p>
      </div>
    </div>
  </div>

  <div v-else class="row">
    <div class="col-xl-12">
      <div class="card">
        <LoadingModal v-if="loading" />
      </div>
    </div>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/shared/PageTitle";
import TableComponent from "@/components/utils/Table";
import { CONSTANTS } from "@/constants/constants";
import LoadingModalComponent from "@/components/utils/LoadingModal";

export default {
  name: "AllUsersView",
  components: {
    PageTitle: PageTitleComponent,
    TableComponent: TableComponent,
    LoadingModal: LoadingModalComponent,
  },
  computed: {
    headers() {
      return CONSTANTS.USER_HEADERS;
    },
    data() {
      return this.$store.getters.allUsers;
    },
    actions() {
      return {
        View: (user) => this.viewUser(user.id),
        Edit: (user) => this.editUser(user.id),
        // Delete: (user) => this.deleteUser(user.id),
      };
    },
    loading() {
      return this.$store.getters.userLoading;
    },
  },
  methods: {
    async goToPage() {
      await this.$store.dispatch("GetUsers");
    },
    viewUser(id) {
      this.$router.push("/users/" + id);
    },
    editUser(id) {
      this.$router.push("/users/edit/" + id);
    },
    async deleteUser(id) {
      await this.$store.dispatch("deleteUser", id);
      await this.goToPage();
    },
  },
  async created() {
    await this.$store.dispatch("getUsers");
  },
  beforeUnmount() {
    console.log("All Users Destroyed");
  },
};
</script>

<style scoped></style>
