<template>
  <page-title pageTitle="Reports" title="Damaged Goods Report" />

  <div class="row pb-4 gy-3 justify-content-between d-print-none">
    <div class="col-sm-auto col-md">
      <div class="d-flex input-group">
        <label class="input-group-text" for="startDate"> Start Date </label>
        <DateInput v-model="startDate" @input="goToPage(1)" />
      </div>
    </div>

    <div class="col-sm-auto col-md">
      <div class="d-flex input-group">
        <label class="input-group-text" for="endDate"> End Date </label>
        <DateInput v-model="endDate" @input="goToPage(1)" />
      </div>
    </div>
  </div>

  <div class="row pb-4 gy-3 justify-content-between d-print-none">
    <div class="col-sm-auto col-md">
      <div class="input-group">
        <label class="input-group-text" for="inputGroupMonth">Month</label>
        <select
          class="form-select"
          id="inputGroupMonth"
          v-model="this.filterMonth"
          @change="goToPage(1)"
          placeholder="Choose..."
        >
          <option value="">Select a month...</option>
          <option
            v-for="month in months"
            :key="month.value"
            :value="month.value"
          >
            {{ month.label }}
          </option>
        </select>
      </div>
    </div>

    <div class="col-sm-auto col-md">
      <div class="input-group">
        <label class="input-group-text" for="inputGroupYear">Year</label>
        <input
          type="text"
          class="form-control"
          id="inputGroupYear"
          v-model="this.filterYear"
          @change="goToPage(1)"
          placeholder="Year"
        />
      </div>
    </div>
  </div>

  <div class="row pb-4 gy-3 justify-content-between d-print-none">
    <div class="col-sm-auto col-md">
      <button @click="printPageAsPDF()" class="btn btn-primary">
        <i class="ri-printer-cloud-line align-bottom me-1"></i> Print
      </button>
    </div>

    <div class="col-sm-auto col-md-8">
      <div class="search-box">
        <input
          type="text"
          class="form-control"
          id="searchMemberList"
          placeholder="Search for Result"
          v-model="searchQuery"
          @input="goToPage(1)"
        />
        <i class="las la-search search-icon"></i>
      </div>
    </div>

    <div class="col-sm-auto col-md-2">
      <div class="input-group">
        <label class="input-group-text" for="inputGroupSelect01">Show</label>
        <select
          class="form-select"
          id="resultsPerPage"
          v-model="resultsPerPage"
          @change="goToPage(1)"
        >
          <option
            v-for="option in resultsPerPageOptions"
            :key="option"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
      </div>
    </div>
  </div>

  <div v-if="!error || error === ''" class="">
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <table-component
              :headers="headers"
              :footer="footer"
              :data="formattedData"
              :offset="(currentPage - 1) * resultsPerPage"
              @sort="goToPage"
            />
          </div>
        </div>
      </div>
    </div>
    <pagination-component
      class="d-print-none"
      :rows-loaded="data.length"
      :current-page="currentPage"
      :num-pages="numPages"
      :total-rows="totalRows"
      :go-to-page="goToPage"
    />
  </div>

  <div v-else class="row">
    <div class="col-xl-12">
      <div class="card">
        <div class="card-body">
          {{ error }}
        </div>
      </div>
    </div>
  </div>
  <LoadingModal v-if="loading" />
</template>

<script>
import PageTitleComponent from "@/components/shared/PageTitle";
import { SETTINGS } from "@/constants/config.js";
import { CONSTANTS } from "@/constants/constants.js";
import LoadingModalComponent from "@/components/utils/LoadingModal";
import PaginationComponent from "@/components/utils/paginationComponent";
import TableComponent from "@/components/utils/Table";
import DateInput from "@/components/utils/DateInput";

export default {
  name: "DamagedGoodsReportView",
  components: {
    DateInput,
    TableComponent,
    PaginationComponent,
    LoadingModal: LoadingModalComponent,
    PageTitle: PageTitleComponent,
  },
  data() {
    return {
      startDate: "",
      endDate: "",
      filterMonth: "",
      filterYear: "",
      searchQuery: "",
      resultsPerPage: SETTINGS.resultsPerPage,
      months: [
        { value: "1", label: "January" },
        { value: "2", label: "February" },
        { value: "3", label: "March" },
        { value: "4", label: "April" },
        { value: "5", label: "May" },
        { value: "6", label: "June" },
        { value: "7", label: "July" },
        { value: "8", label: "August" },
        { value: "9", label: "September" },
        { value: "10", label: "October" },
        { value: "11", label: "November" },
        { value: "12", label: "December" },
      ],
    };
  },
  computed: {
    headers() {
      return {
        barcode: "Barcode",
        productName: "Product Name",
        totalDamagedPrice: "Total Selling Price",
        totalLoss: "Total Loss",
        totalQuantityDamaged: "Total Quantity Damaged",
      };
    },
    footer() {
      return [
        "",
        "Product Totals",
        this.priceFormat(this.totalDamagedPrice.toFixed(2)),
        this.priceFormat(this.totalLoss.toFixed(2)),
        this.totalQuantityDamaged,
      ];
    },
    formattedData() {
      const productAnalysis =
        this.$store.getters.damagedGoodsReport.productAnalysis;
      return productAnalysis.map((product) => ({
        ...product,
        totalCostPrice: this.priceFormat(product.totalCostPrice),
        totalDamagedPrice: this.priceFormat(product.totalDamagedPrice),
        totalLoss: this.priceFormat(product.totalLoss),
      }));
    },
    data() {
      return this.$store.getters.damagedGoodsReport.productAnalysis;
    },
    totalCostPrice() {
      if (!this.data || this.data.length === 0) {
        return 0;
      }
      return this.data.reduce(
        (total, item) => total + parseFloat(item.totalCostPrice),
        0
      );
    },
    totalDamagedPrice() {
      if (!this.data || this.data.length === 0) {
        return 0;
      }
      return this.data.reduce(
        (total, item) => total + parseFloat(item.totalDamagedPrice),
        0
      );
    },
    totalQuantityDamaged() {
      if (!this.data || this.data.length === 0) {
        return 0;
      }
      return this.data.reduce((total, item) => {
        // Parse the value to ensure it's a number before adding
        const quantity = parseFloat(item.totalQuantityDamaged);
        return total + (isNaN(quantity) ? 0 : quantity);
      }, 0);
    },
    totalLoss() {
      if (!this.data || this.data.length === 0) {
        return 0;
      }
      return this.data.reduce(
        (total, item) => total + parseFloat(item.totalLoss),
        0
      );
    },
    currentPage() {
      return this.$store.getters.damagedGoodsReport.currentPage;
    },
    numPages() {
      return this.$store.getters.damagedGoodsReport.numPages;
    },
    totalRows() {
      return this.$store.getters.damagedGoodsReport.totalRows;
    },
    resultsPerPageOptions() {
      const options = CONSTANTS.RESULTS_PER_PAGE_OPTIONS;
      if (!options.includes(this.resultsPerPage)) {
        options.unshift(this.resultsPerPage);
      }
      return options;
    },
    error() {
      return this.$store.getters.damagedGoodsReport.error;
    },
    loading() {
      return this.$store.getters.damagedGoodsReport.loading;
    },
  },
  methods: {
    priceFormat(price) {
      return `$${price
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`.replace("$-", "-$");
    },
    async goToPage(page, sortBy = null) {
      let filter = {
        sortingList: sortBy,
        currentPage: page,
        resultsPerPage:
          this.resultsPerPage === "ALL" ? this.totalRows : this.resultsPerPage,
        startDate: this.startDate,
        endDate: this.endDate,
        month: this.filterMonth,
        year: this.filterYear,
        status: this.status,
        searchQuery: this.searchQuery,
      };
      await this.$store.dispatch("getDamagedGoodsReport", filter);
    },
    async printPageAsPDF() {
      window.print();
    },
  },
  async created() {
    let filter = {
      resultsPerPage: SETTINGS.resultsPerPage,
    };
    await this.$store.dispatch("getDamagedGoodsReport", filter);
  },
  beforeUnmount() {
    console.log("All Billers Destroyed");
  },
};
</script>

<style scoped>
@media print {
  .card-body {
    padding-top: 5mm;
  }
}
</style>
