<template>
  <page-title pageTitle="Expense" title="New Expense" />
  <expenses-form :expense="{}" :submitFunction="addExpense" />
</template>

<script>
import PageTitleComponent from "@/components/shared/PageTitle";
import ExpensesForm from "@/components/expense/ExpensesForm";

export default {
  name: "NewExpenseView",
  components: {
    PageTitle: PageTitleComponent,
    ExpensesForm: ExpensesForm,
  },
  data() {
    return {
      id: -1,
      expense: null,
    };
  },
  methods: {
    async addExpense(expense) {
      await this.$store.dispatch("addExpense", expense);
      this.$router.push("/expenses");
    },
  },
};
</script>

<style scoped></style>
