import axios from "axios";
import { CONFIG } from "@/constants/config.js";

const expensesBackend = CONFIG.BACKEND_URL + "expenses";

const state = {
  expense: {},
  expenses: [],
  loading: false,
  error: "",
  currentPage: 1,
  numPages: 1,
  totalRows: 0,
};

const config = () => ({
  headers: {
    Token: localStorage.getItem("accessToken"),
  },
});

const getters = {
  expense: (state) => state.expense,
  allExpenses: (state) => state.expenses,
  expensesLoading: (state) => state.loading,
  expensesError: (state) => state.error,
  expensesCurrentPage: (state) => state.currentPage,
  expensesNumPages: (state) => state.numPages,
  expensesTotalRows: (state) => state.totalRows,
};

const actions = {
  async getExpense({ commit /*, dispatch */ }, id) {
    commit("setLoading", true);
    await axios
      .post(`${expensesBackend}/${id}/`, null, config())
      .then(async (response) => {
        commit("setLoading", false);
        await commit("setExpense", response.data);
        commit("setError", "");
      })
      .catch(async (error) => {
        if (error.response) {
          await commit("reset");
          commit("setError", error.response.data.message);
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
          console.log(error);
        }
        commit("setLoading", false);
      });
  },
  async getExpenses({ commit /*, dispatch */ }, filter = null) {
    commit("setLoading", true);
    await axios
      .post(`${expensesBackend}/all/`, filter, config())
      .then(async (response) => {
        commit("setLoading", false);
        await commit("setExpenses", response.data);
        commit("setError", "");
      })
      .catch(async (error) => {
        if (error.response) {
          await commit("reset");
          commit("setError", error.response.data.message);
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
          console.log(error);
        }
      });
  },
  async addExpense({ commit /*, dispatch */ }, expense) {
    commit("setLoading", true);
    await axios
      .post(`${expensesBackend}/insert/`, expense, config())
      .then(async () => {
        commit("setLoading", false);
        commit("setError", "");
      })
      .catch(async (error) => {
        if (error.response) {
          await commit("reset");
          commit("setError", error.response.data.message);
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
          console.log(error);
        }
      });
  },
  // eslint-disable-next-line no-unused-vars
  async editExpense({ commit, dispatch }, expense) {
    commit("setLoading", true);
    await axios
      .patch(`${expensesBackend}/update/`, expense, config())
      .then(async () => {
        commit("setLoading", false);
        commit("setError", "");
      })
      .catch(async (error) => {
        if (error.response) {
          await commit("reset");
          commit("setError", error.response.data.message);
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
          console.log(error);
        }
      });
  },
  // eslint-disable-next-line no-unused-vars
  async deleteExpense({ commit, dispatch }, id) {
    commit("setLoading", true);
    await axios
      .delete(`${expensesBackend}/delete/${id}/`, config())
      .then(async () => {
        commit("setLoading", false);
        commit("setError", "");
      })
      .catch(async (error) => {
        if (error.response) {
          await commit("reset");
          commit("setError", error.response.data.message);
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
          console.log(error);
        }
      });
  },
  async resetExpense({ commit }) {
    await commit("reset");
  },
};

const mutations = {
  reset(state) {
    state.expense = {};
    state.expenses = [];
    state.loading = false;
    state.error = "";
    state.currentPage = 1;
    state.numPages = 1;
    state.totalRows = 0;
  },
  setExpense(state, response) {
    state.expense = response;
  },
  setExpenses(state, response) {
    state.expenses = response.expenses;
    state.currentPage = response.currentPage;
    state.numPages = response.numPages;
    state.totalRows = response.totalRows;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setError(state, error) {
    state.error = error;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
