import axios from "axios";
import { CONFIG } from "@/constants/config.js";

const buyersBackend = CONFIG.BACKEND_URL + "customer";

const state = {
  buyer: {},
  buyers: [],
  loading: false,
  error: "",
  currentPage: 1,
  numPages: 1,
  totalRows: 0,
};

const config = () => ({
  headers: {
    Token: localStorage.getItem("accessToken"),
  },
});

const getters = {
  buyer: (state) => state.buyer,
  allBuyers: (state) => state.buyers,
  buyersLoading: (state) => state.loading,
  buyersError: (state) => state.error,
  buyersCurrentPage: (state) => state.currentPage,
  buyersNumPages: (state) => state.numPages,
  buyersTotalRows: (state) => state.totalRows,
  getBuyerById: (state) => (id) => {
    return state.buyers.find((buyer) => buyer.id === id);
  },
};

const actions = {
  async getBuyer({ commit /*, dispatch */ }, id) {
    commit("setLoading", true);
    await axios
      .post(`${buyersBackend}/${id}/`, null, config())
      .then(async (response) => {
        commit("setLoading", false);
        await commit("setBuyer", response.data);
        commit("setError", "");
      })
      .catch(async (error) => {
        if (error.response) {
          await commit("reset");
          commit("setError", error.response.data.message);
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
          console.log(error);
        }
        commit("setLoading", false);
      });
  },
  async getBuyers({ commit /*, dispatch */ }, filter = null) {
    commit("setLoading", true);
    await axios
      .post(`${buyersBackend}/all/`, filter, config())
      .then(async (response) => {
        commit("setLoading", false);
        await commit("setBuyers", response.data);
        commit("setError", "");
      })
      .catch(async (error) => {
        if (error.response) {
          await commit("reset");
          commit("setError", error.response.data.message);
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
          console.log(error);
        }
      });
  },
  async getDeletedBuyers({ commit /*, dispatch */ }, filter = null) {
    commit("setLoading", true);
    await axios
      .post(`${buyersBackend}/all/deleted/`, filter, config())
      .then(async (response) => {
        commit("setLoading", false);
        await commit("setBuyers", response.data);
        commit("setError", "");
      })
      .catch(async (error) => {
        if (error.response) {
          await commit("reset");
          commit("setError", error.response.data.message);
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
          console.log(error);
        }
      });
  },
  async addBuyer({ commit /*, dispatch */ }, buyer) {
    commit("setLoading", true);
    await axios
      .post(`${buyersBackend}/new/`, buyer, config())
      .then(async () => {
        commit("setLoading", false);
        commit("setError", "");
      })
      .catch(async (error) => {
        if (error.response) {
          await commit("reset");
          commit("setError", error.response.data.message);
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
          console.log(error);
        }
      });
  },
  // eslint-disable-next-line no-unused-vars
  async editBuyer({ commit, dispatch }, buyer) {
    commit("setLoading", true);
    await axios
      .patch(`${buyersBackend}/update/`, buyer, config())
      .then(async () => {
        commit("setLoading", false);
        commit("setError", "");
      })
      .catch(async (error) => {
        if (error.response) {
          await commit("reset");
          commit("setError", error.response.data.message);
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
          console.log(error);
        }
      });
  },
  // eslint-disable-next-line no-unused-vars
  async deleteBuyer({ commit, dispatch }, id) {
    commit("setLoading", true);
    await axios
      .delete(`${buyersBackend}/delete/${id}/`, config())
      .then(async () => {
        commit("setLoading", false);
        commit("setError", "");
      })
      .catch(async (error) => {
        if (error.response) {
          await commit("reset");
          commit("setError", error.response.data.message);
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
          console.log(error);
        }
      });
  },
  // eslint-disable-next-line no-unused-vars
  async recoverBuyer({ commit, dispatch }, id) {
    commit("setLoading", true);
    await axios
      .post(`${buyersBackend}/recover/${id}/`, config())
      .then(async () => {
        commit("setLoading", false);
        commit("setError", "");
        // dispatch(
        //   "notification/AddNotification",
        //   { type: "success", message: "Product Edited Successfully Created" },
        //   { root: true }
        // );
      })
      .catch((error) => {
        if (error.response.data.message) {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: error.message },
          //   { root: true }
          // );
        }
        commit("setLoading", false);
        commit("setError", error.data.message);
        throw error;
      });
  },
  async resetCustomer({ commit }) {
    await commit("reset");
  },
};

const mutations = {
  reset(state) {
    state.buyer = {};
    state.buyers = [];
    state.loading = false;
    state.error = "";
    state.currentPage = 1;
    state.numPages = 1;
    state.totalRows = 0;
  },
  setBuyer(state, response) {
    state.buyer = response;
  },
  setBuyers(state, response) {
    state.buyers = response.customers;
    state.currentPage = response.currentPage;
    state.numPages = response.numPages;
    state.totalRows = response.totalRows;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setError(state, error) {
    state.error = error;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
