import axios from "axios";
import { CONFIG } from "@/constants/config.js";

const barcodesBackend = CONFIG.BACKEND_URL + "barcode";

const state = {
  barcodes: [],
  loading: false,
  error: "",
};

const config = () => ({
  headers: {
    Token: localStorage.getItem("accessToken"),
  },
});

const getters = {
  barcodes: (state) => state.barcodes,
  barcodesLoading: (state) => state.loading,
  barcodesError: (state) => state.error,
};

const actions = {
  // eslint-disable-next-line no-unused-vars
  async generateBarcode({ commit, dispatch }, product) {
    // commit("setLoading", true);
    await axios
      .post(`${barcodesBackend}/generate/`, product, config())
      .then(async (response) => {
        // commit("setLoading", false);
        commit("setError", "");
        product.barcode = response.data.barcode;
        // dispatch(
        //   "notification/AddNotification",
        //   { type: "success", message: "Product Edited Successfully Created" },
        //   { root: true }
        // );
      })
      .catch((error) => {
        if (error.response.data.message) {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: error.message },
          //   { root: true }
          // );
          commit("setError", error.response.data.message);
          alert(error.response.data.message);
        }
        // commit("setLoading", false);
      });
  },
  async getBarcodeItems({ commit /*, dispatch */ }) {
    commit("setLoading", true);
    await axios
      .post(`${barcodesBackend}/all/`, null, config())
      .then(async (response) => {
        commit("setLoading", false);
        await commit("setBarcodes", response.data);
        commit("setError", "");
      })
      .catch(async (error) => {
        commit("setLoading", false);
        if (error.response) {
          await commit("reset");
          if (error.response.status != 404) {
            alert(error.response.data.message);
          }
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
        }
        console.log(error);
      });
  },
  async insertBarcode({ commit /*, dispatch */ }, barcode) {
    commit("setLoading", true);
    await axios
      .post(`${barcodesBackend}/insert/`, barcode, config())
      .then(async () => {
        commit("setLoading", false);
        commit("setError", "");
      })
      .catch(async (error) => {
        commit("setLoading", false);
        if (error.response) {
          await commit("reset");
          // commit("setError", error.response.data.message);
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
          console.log(error);
        }
      });
  },
  // eslint-disable-next-line no-unused-vars
  async updateBarcode({ commit /*, dispatch */ }, barcode) {
    commit("setLoading", true);
    await axios
      .patch(`${barcodesBackend}/update/`, barcode, config())
      .then(async () => {
        commit("setLoading", false);
        commit("setError", "");
      })
      .catch(async (error) => {
        commit("setLoading", false);
        if (error.response) {
          await commit("reset");
          // commit("setError", error.response.data.message);
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
          console.log(error);
        }
      });
  },
  // eslint-disable-next-line no-unused-vars
  async deleteBarcodeItem({ commit, dispatch }, id) {
    commit("setLoading", true);
    await axios
      .delete(`${barcodesBackend}/delete/${id}/`, config())
      .then(async () => {
        commit("setLoading", false);
        commit("setError", "");
      })
      .catch(async (error) => {
        commit("setLoading", false);
        if (error.response) {
          await commit("reset");
          // commit("setError", error.response.data.message);
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
          console.log(error);
        }
      });
  },
  // eslint-disable-next-line no-unused-vars
  async deleteAllBarcodeItems({ commit, dispatch }) {
    commit("setLoading", true);
    await axios
      .delete(`${barcodesBackend}/delete/all/`, config())
      .then(async () => {
        commit("setLoading", false);
        commit("setError", "");
      })
      .catch(async (error) => {
        commit("setLoading", false);
        if (error.response) {
          await commit("reset");
          // commit("setError", error.response.data.message);
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
          console.log(error);
        }
      });
  },
};

const mutations = {
  addBarcode(state, barcode) {
    state.barcodes.push(barcode);
  },
  reset(state) {
    state.barcodes = [];
    state.barcodeItem = {};
    state.barcode = [];
    state.loading = false;
    state.error = "";
    state.currentPage = 1;
    state.numPages = 1;
    state.totalRows = 0;
  },
  setBarcodes(state, response) {
    state.barcodes = response.barcodeItems;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setError(state, error) {
    state.error = error;
  },
  setProductHistory(state, response) {
    state.productHistory = response;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
