<template>
  <div class="row">
    <div class="col-xl-12">
      <div class="card">
        <div class="card-body">
          <form
            @submit.prevent="submit(updatedProductGroup)"
            :class="{ 'disabled-form': disabled }"
          >
            <div class="p-2">
              <div class="mb-3">
                <label class="form-label" for="name">Name</label>
                <input
                  id="name"
                  name="name"
                  placeholder="Enter Price Group Name"
                  type="text"
                  class="form-control"
                  v-model="updatedProductGroup.name"
                  :disabled="disabled"
                />
              </div>
              <div class="mb-3">
                <label class="form-label" for="name">Cost Price</label>
                <input
                  id="name"
                  name="name"
                  placeholder="Enter Cost Price"
                  type="text"
                  class="form-control"
                  v-model="updatedProductGroup.costPrice"
                  :disabled="disabled"
                />
              </div>
              <div class="mb-3">
                <label class="form-label" for="name">Selling Price</label>
                <input
                  id="name"
                  name="name"
                  placeholder="Enter Selling Price"
                  type="text"
                  class="form-control"
                  v-model="updatedProductGroup.sellingPrice"
                  :disabled="disabled"
                />
              </div>
              <div class="mb-3">
                <label class="form-label" for="name">
                  Suggested Retail Price
                </label>
                <input
                  id="name"
                  name="name"
                  placeholder="Enter Suggested Retail Price"
                  type="text"
                  class="form-control"
                  v-model="updatedProductGroup.suggestedRetailPrice"
                  :disabled="disabled"
                />
              </div>
              <div class="hstack gap-2 mt-4">
                <button v-if="!disabled" type="submit" class="btn btn-primary">
                  Save
                </button>
                <button
                  v-if="disabled"
                  type="button"
                  class="btn btn-primary"
                  @click="edit"
                >
                  Edit
                </button>
                <button type="button" class="btn btn-light" @click="discard">
                  Back
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductGroupForm",
  props: {
    productGroup: {
      type: Object,
      required: true,
    },
    submitFunction: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      updatedProductGroup: { ...this.productGroup },
    };
  },
  methods: {
    submit() {
      if (!this.disabled) {
        this.submitFunction(this.updatedProductGroup);
      }
    },
    discard() {
      this.updatedProductGroup = { ...this.productGroup };
      this.$router.push("/productGroups/");
    },
    edit() {
      this.$router.push("/productGroups/edit/" + this.productGroup.id);
    },
  },
  computed: {
    disabled() {
      return typeof this.submitFunction !== "function";
    },
  },
};
</script>

<style scoped>
.disabled-form input {
  pointer-events: none;
  opacity: 0.6;
}
</style>
