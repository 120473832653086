<template>
  <div class="card px-3">
    <div class="card-header">
      <h4 class="card-title mb-0 text-center">Change Password</h4>
    </div>
    <div class="card-body">
      <form @submit.prevent="changePassword" class="auth-input mt-4 pb-5">
        <div class="mb-3">
          <label for="username" class="form-label">Username</label>
          <input
            type="text"
            class="form-control"
            id="username"
            v-model="username"
            placeholder="Enter username"
          />
        </div>

        <div class="mb-2">
          <label for="userpassword" class="form-label">Current Password</label>
          <div class="position-relative auth-pass-inputgroup mb-3">
            <input
              :type="showCurrentPassword ? 'text' : 'password'"
              class="form-control pe-5 password-input"
              placeholder="Enter current password"
              v-model="currentPassword"
              id="password-input"
            />
            <button
              class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
              type="button"
              id="password-addon"
              @click="showCurrentPassword = !showCurrentPassword"
            >
              <i class="las la-eye align-middle fs-18"></i>
            </button>
          </div>
        </div>

        <div class="mb-2">
          <label for="userpassword" class="form-label">New Password</label>
          <div class="position-relative auth-pass-inputgroup mb-3">
            <input
              :type="showNewPassword ? 'text' : 'password'"
              class="form-control pe-5 password-input"
              placeholder="Enter new password"
              v-model="newPassword"
              id="password-input"
            />
            <button
              class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
              type="button"
              id="password-addon"
              @click="showNewPassword = !showNewPassword"
            >
              <i class="las la-eye align-middle fs-18"></i>
            </button>
          </div>
        </div>

        <div class="mb-2">
          <label for="userpassword" class="form-label"
            >Confirm New Password</label
          >
          <div class="position-relative auth-pass-inputgroup mb-3">
            <input
              :type="showConfirmNewPassword ? 'text' : 'password'"
              class="form-control pe-5 password-input"
              placeholder="Confirm new password"
              v-model="confirmNewPassword"
              id="password-input"
            />
            <button
              class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
              type="button"
              id="password-addon"
              @click="showConfirmNewPassword = !showConfirmNewPassword"
            >
              <i class="las la-eye align-middle fs-18"></i>
            </button>
          </div>
        </div>

        <a href="/signIn" class="text-muted text-decoration-underline fs-14">
          Sign In?
        </a>

        <div class="mt-3">
          <button class="btn btn-primary w-100" type="submit">
            Change Password
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChangePasswordView",
  data() {
    return {
      username: "",
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      showCurrentPassword: false,
      showNewPassword: false,
      showConfirmNewPassword: false,
    };
  },
  methods: {
    async changePassword() {
      const user = {};
      user.email = this.username;
      user.currentPassword = this.currentPassword;
      user.newPassword = this.newPassword;
      await this.$store.dispatch("ChangePassword", user);
    },
  },
};
</script>

<style scoped>
.card {
  margin: 10vh 10vw;
}
</style>
