<template>
  <page-title pageTitle="Customer" title="Edit Customer" />
  <buyer-form v-if="buyer.id" :buyer="buyer" :submitFunction="editBuyer" />
  <div v-else class="card">
    <LoadingModal v-if="loading" />
    <div v-else-if="error" class="card-body">
      {{ error }}
    </div>
    <div v-else class="card-body">The customer doesn't exist</div>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/shared/PageTitle";
import BuyerFormComponent from "@/components/buyer/BuyerForm";
import LoadingModalComponent from "@/components/utils/LoadingModal";

export default {
  name: "EditBuyerView",
  components: {
    PageTitle: PageTitleComponent,
    BuyerForm: BuyerFormComponent,
    LoadingModal: LoadingModalComponent,
  },
  data() {
    return {
      id: -1,
    };
  },
  computed: {
    buyer() {
      return this.$store.getters.buyer;
    },
    error() {
      return this.$store.getters.buyersError;
    },
    loading() {
      return this.$store.getters.buyersLoading;
    },
  },
  methods: {
    async editBuyer(buyer) {
      await this.$store.dispatch("editBuyer", buyer);
      this.$router.push("/customers");
    },
  },
  async created() {
    this.id = parseInt(this.$route.params.id);
    await this.$store.dispatch("getBuyer", this.id);
  },
};
</script>

<style scoped></style>
