import axios from "axios";
import { CONFIG } from "@/constants/config.js";
import router from "@/router";

const invoicesBackend = CONFIG.BACKEND_URL + "invoice";

const state = {
  invoiceSummary: {},
  invoiceTotals: {},
  invoices: [],
  invoiceItem: {},
  invoice: [],
  loading: false,
  error: "",
  currentPage: 1,
  numPages: 1,
  totalRows: 0,
  productHistory: {},
};

const config = () => ({
  headers: {
    Token: localStorage.getItem("accessToken"),
  },
});

const getters = {
  invoiceSummary: (state) => state.invoiceSummary,
  invoiceTotals: (state) => state.invoiceTotals,
  allInvoices: (state) => state.invoices,
  invoiceItem: (state) => state.invoiceItem,
  invoice: (state) => state.invoice,
  invoicesLoading: (state) => state.loading,
  invoicesError: (state) => state.error,
  invoicesCurrentPage: (state) => state.currentPage,
  invoicesNumPages: (state) => state.numPages,
  invoicesTotalRows: (state) => state.totalRows,
  productHistory: (state) => state.productHistory,
  getInvoiceByID: (state) => (id) => {
    return state.invoices.find((invoice) => invoice.id === id);
  },
};

const actions = {
  async getInvoice({ commit /*, dispatch */ }, id) {
    commit("setLoading", true);
    await axios
      .post(`${invoicesBackend}/summary/${id}/`, null, config())
      .then(async (response) => {
        commit("setLoading", false);
        await commit("setInvoiceSummary", response.data);
        commit("setError", "");
      })
      .catch(async (error) => {
        commit("setLoading", false);
        await router.push("/invoices");
        if (error.response) {
          await commit("resetInvoiceSummary");
          alert(error.response.data.message);
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
        }
        console.log(error);
      });
  },
  async getInvoices({ commit /*, dispatch */ }, filter = null) {
    commit("setLoading", true);
    await axios
      .post(`${invoicesBackend}/all/`, filter, config())
      .then(async (response) => {
        commit("setLoading", false);
        await commit("setInvoices", response.data);
        commit("setError", "");
      })
      .catch(async (error) => {
        commit("setLoading", false);
        if (error.response) {
          await commit("reset");
          commit("setError", error.response.data.message);
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
          console.log(error);
        }
      });
  },
  async getInvoiceItems({ commit /*, dispatch */ }, { id, filter = null }) {
    commit("setLoading", true);
    console.log(filter);
    await axios
      .post(`${invoicesBackend}/${id}/`, filter, config())
      .then(async (response) => {
        commit("setLoading", false);
        await commit("setInvoice", response.data);
        commit("setError", "");
      })
      .catch(async (error) => {
        commit("setLoading", false);
        if (error.response) {
          await commit("reset");
          // commit("setError", error.response.data.message);
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
          console.log(error);
        }
      });
  },
  async addInvoice({ commit /*, dispatch */ }) {
    commit("setLoading", true);
    await axios
      .post(`${invoicesBackend}/new/`, null, config())
      .then(async (response) => {
        commit("setLoading", false);
        commit("setError", "");
        await router.push(`/invoices/edit/${response.data.invoice.id}`);
      })
      .catch(async (error) => {
        commit("setLoading", false);
        if (error.response) {
          await commit("reset");
          // commit("setError", error.response.data.message);
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
          console.log(error);
        }
      });
  },
  async addInvoiceItem({ commit /*, dispatch */ }, invoiceItem) {
    commit("setLoading", true);
    await axios
      .post(`${invoicesBackend}/insert/`, invoiceItem, config())
      .then(async () => {
        commit("setLoading", false);
        commit("setError", "");
      })
      .catch(async (error) => {
        commit("setLoading", false);
        if (error.response) {
          await commit("reset");
          alert(error.response.data.message);
          // commit("setError", error.response.data.message);
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
          console.log(error);
        }
      });
  },
  // eslint-disable-next-line no-unused-vars
  async editInvoice({ commit /*, dispatch */ }, invoice) {
    commit("setLoading", true);
    await axios
      .patch(`${invoicesBackend}/update/`, invoice, config())
      .then(async () => {
        commit("setLoading", false);
        commit("setError", "");
      })
      .catch(async (error) => {
        commit("setLoading", false);
        if (error.response) {
          await commit("reset");
          // commit("setError", error.response.data.message);
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
          console.log(error);
        }
      });
  },
  async editInvoiceItem({ commit /*, dispatch */ }, invoiceItem) {
    commit("setLoading", true);
    await axios
      .patch(`${invoicesBackend}/update/item/`, invoiceItem, config())
      .then(async () => {
        commit("setLoading", false);
        commit("setError", "");
      })
      .catch(async (error) => {
        commit("setLoading", false);
        if (error.response) {
          await commit("reset");
          // commit("setError", error.response.data.message);
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
          console.log(error);
        }
      });
  },
  // eslint-disable-next-line no-unused-vars
  async deleteInvoice({ commit, dispatch }, id) {
    commit("setLoading", true);
    await axios
      .delete(`${invoicesBackend}/delete/${id}/`, config())
      .then(async () => {
        commit("setLoading", false);
        commit("setError", "");
      })
      .catch(async (error) => {
        commit("setLoading", false);
        if (error.response) {
          await commit("reset");
          // commit("setError", error.response.data.message);
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
          console.log(error);
        }
      });
  },
  // eslint-disable-next-line no-unused-vars
  async deleteInvoiceItem({ commit, dispatch }, id) {
    commit("setLoading", true);
    await axios
      .delete(`${invoicesBackend}/delete/item/${id}/`, config())
      .then(async () => {
        commit("setLoading", false);
        commit("setError", "");
      })
      .catch(async (error) => {
        commit("setLoading", false);
        if (error.response) {
          await commit("reset");
          // commit("setError", error.response.data.message);
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
          console.log(error);
        }
      });
  },
  async getProductHistory({ commit /*, dispatch */ }, invoiceItemID) {
    commit("setLoading", true);
    await axios
      .post(
        `${invoicesBackend}/productHistory/${invoiceItemID}/`,
        null,
        config()
      )
      .then(async (response) => {
        commit("setLoading", false);
        await commit("setProductHistory", response.data);
        commit("setError", "");
      })
      .catch(async (error) => {
        commit("setLoading", false);
        if (error.response) {
          alert(error.response.data.message);
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
        }
        console.log(error);
      });
  },
  // eslint-disable-next-line no-unused-vars
  async emailInvoice({ commit, dispatch }, { id, formData }) {
    commit("setLoading", true);
    await axios
      .post(`${invoicesBackend}/send/${id}/`, formData, config())
      .then(async () => {
        commit("setLoading", false);
        commit("setError", "");
      })
      .catch(async (error) => {
        commit("setLoading", false);
        if (error.response) {
          await commit("reset");
          // commit("setError", error.response.data.message);
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
          console.log(error);
        }
      });
  },
  async resetInvoice({ commit }) {
    await commit("reset");
  },
};

const mutations = {
  addInvoice(state, invoice) {
    state.invoices.push(invoice);
  },
  resetInvoiceSummary(state) {
    state.invoiceSummary = {};
  },
  reset(state) {
    state.invoices = [];
    state.invoiceItem = {};
    state.invoice = [];
    state.loading = false;
    state.error = "";
    state.currentPage = 1;
    state.numPages = 1;
    state.totalRows = 0;
  },
  setInvoiceSummary(state, response) {
    state.invoiceSummary = response.invoiceSummary;
    state.invoiceTotals = response.invoiceTotals;
  },
  setInvoices(state, response) {
    state.invoices = response.invoice;
    state.currentPage = response.currentPage;
    state.numPages = response.numPages;
    state.totalRows = response.totalRows;
  },
  setInvoiceItem(state, response) {
    state.invoiceItem = response;
  },
  setInvoice(state, response) {
    state.invoice = response.invoiceItems;
    state.currentPage = response.currentPage;
    state.numPages = response.numPages;
    state.totalRows = response.totalRows;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setError(state, error) {
    state.error = error;
  },
  setProductHistory(state, response) {
    state.productHistory = response;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
