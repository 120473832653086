<template>
  <div class="card-body p-4 pb-0">
    <div class="row mb-2">
      <div class="col-sm-auto col-md">
        <label for="invoicenoInput">Invoice No</label>
        <input
          type="text"
          class="form-control bg-light border-0"
          id="invoicenoInput"
          placeholder="Invoice No"
          :value="data.invoiceNo"
          disabled
        />
      </div>
      <div class="col-sm-auto col-md">
        <biller-input-group :key="data.billerID" />
      </div>
      <div class="col-sm-auto col-md">
        <buyer-input-group :key="data.customerID" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-sm-auto col-md">
        <label for="date-field">Date</label>
        <DateInput v-model="invoiceDate" @input="updateInvoice('date')" />
      </div>
      <div class="col-sm-auto col-md">
        <label for="totalamountInput">Amount Paid</label>
        <input
          type="text"
          class="form-control bg-light border-0"
          id="totalamountInput"
          :value="priceFormat(data.paid)"
          placeholder="0"
          disabled
        />
      </div>
      <div class="col-sm-auto col-md">
        <label for="totalamountInput">Amount Due</label>
        <input
          type="text"
          class="form-control bg-light border-0"
          id="totalamountInput"
          :value="priceFormat(data.balance)"
          disabled
        />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-sm-auto col-md">
        <label for="choices-payment-status">Payment Status</label>
        <input
          type="text"
          class="form-control bg-light border-0"
          id="choices-payment-status"
          v-model="data.status"
          disabled
        />
      </div>
      <div class="col-sm-auto col-md">
        <label for="discountRedeemed">Discount Redeemed</label>
        <input
          type="text"
          class="form-control bg-light border-0"
          id="discountRedeemed"
          :value="priceFormat(data.discountRedeemed)"
          disabled
        />
      </div>
      <div class="col-sm-auto col-md">
        <label for="awardPointsRedeemed">Award Points Redeemed</label>
        <input
          type="text"
          class="form-control bg-light border-0"
          id="awardPointsRedeemed"
          v-model="data.awardPointsRedeemed"
          disabled
        />
      </div>
    </div>
    <div class="form-check mb-2">
      <input
        class="form-check-input"
        v-model="data.showSuggestedAnalysis"
        :true-value="1"
        :false-value="0"
        @change="updateInvoice('showSuggestedAnalysis')"
        type="checkbox"
        id="formCheck1"
      />
      <label class="form-check-label" for="formCheck1">
        Show Suggested Analysis
      </label>
    </div>
    <div class="form-check mb-2">
      <input
        class="form-check-input"
        v-model="data.showIndividualMargin"
        :true-value="1"
        :false-value="0"
        @change="updateInvoice('showIndividualMargin')"
        type="checkbox"
        id="formCheck1"
      />
      <label class="form-check-label" for="formCheck1">
        Show Individual Margin
      </label>
    </div>
    <div class="form-check mb-2">
      <input
        class="form-check-input"
        v-model="data.showDamagedGoods"
        :true-value="1"
        :false-value="0"
        @change="updateInvoice('showDamagedGoods')"
        type="checkbox"
        id="formCheck1"
      />
      <label class="form-check-label" for="formCheck1">
        Show Damaged Goods
      </label>
    </div>
    <div class="form-check mb-2">
      <input
        class="form-check-input"
        v-model="data.mergeInvoiceItems"
        :true-value="1"
        :false-value="0"
        @change="updateInvoice('mergeInvoiceItems')"
        type="checkbox"
        id="formCheck1"
      />
      <label class="form-check-label" for="formCheck1">
        Merge New Invoice Lines
      </label>
    </div>
  </div>
</template>

<script>
import { CONSTANTS } from "@/constants/constants";
import BillerInputGroup from "@/components/invoice/inputGroups/BillerInputGroup";
import BuyerInputGroup from "@/components/invoice/inputGroups/BuyerInputGroup";
import DateInput from "@/components/utils/DateInput";

export default {
  name: "InvoiceDetailsInputGroup",
  components: { DateInput, BuyerInputGroup, BillerInputGroup },
  data() {
    return {
      invoiceDate: "",
    };
  },
  computed: {
    headers() {
      return CONSTANTS.INVOICE_HEADERS;
    },
    data() {
      return this.$store.getters.invoiceSummary;
    },
  },
  methods: {
    async updateInvoice(key) {
      if (key === "date") {
        this.data[key] = this.invoiceDate;
      }
      await this.$store.dispatch("editInvoice", {
        id: this.data.id,
        [key]: this.data[key],
      });
      if (
        ![
          "showDamagedGoods",
          "showIndividualMargin",
          "showSuggestedAnalysis",
          "mergeInvoiceItems",
        ].includes(key)
      ) {
        await this.$store.dispatch("getInvoice", this.data.id);
      }
    },
    priceFormat(price) {
      return `$${price
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`.replace("$-", "-$");
    },
  },
  async created() {
    this.invoiceDate = this.$store.getters.invoiceSummary.date;
  },
};
</script>

<style scoped>
select {
  webkit-appearance: auto;
  -moz-appearance: button-arrow-down;
  appearance: auto;
}
</style>
