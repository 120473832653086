<template>
  <page-title pageTitle="Customer" title="New Customer" />
  <buyer-form :buyer="{}" :submitFunction="addBuyer" />
</template>

<script>
import PageTitleComponent from "@/components/shared/PageTitle";
import BuyerFormComponent from "@/components/buyer/BuyerForm";

export default {
  name: "NewBuyerView",
  components: { PageTitle: PageTitleComponent, BuyerForm: BuyerFormComponent },
  data() {
    return {
      id: -1,
      buyer: null,
    };
  },
  methods: {
    async addBuyer(buyer) {
      await this.$store.dispatch("addBuyer", buyer);
      this.$router.push("/customers");
    },
  },
};
</script>

<style scoped></style>
