<template>
  <page-title pageTitle="Expense" title="Edit Expense" />
  <expenses-form
    v-if="expense.id"
    :expense="expense"
    :submitFunction="editExpense"
  />
  <div v-else class="card">
    <LoadingModal v-if="loading" />
    <div v-else-if="error" class="card-body">
      {{ error }}
    </div>
    <div v-else class="card-body">The expense doesn't exist</div>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/shared/PageTitle";
import ExpensesForm from "@/components/expense/ExpensesForm";
import LoadingModalComponent from "@/components/utils/LoadingModal";

export default {
  name: "EditExpenseView",
  components: {
    PageTitle: PageTitleComponent,
    ExpensesForm: ExpensesForm,
    LoadingModal: LoadingModalComponent,
  },
  data() {
    return {
      id: -1,
    };
  },
  computed: {
    expense() {
      return this.$store.getters.expense;
    },
    error() {
      return this.$store.getters.expensesError;
    },
    loading() {
      return this.$store.getters.expensesLoading;
    },
  },
  methods: {
    async editExpense(expense) {
      await this.$store.dispatch("editExpense", expense);
      this.$router.push("/expenses");
    },
  },
  async created() {
    this.id = parseInt(this.$route.params.id);
    await this.$store.dispatch("getExpense", this.id);
  },
  unmounted() {
    this.$store.dispatch("resetExpense", this.id);
  },
};
</script>

<style scoped></style>
