<template v-if="false">
  <page-title pageTitle="Dashboard" title="Dashboard" />

  <overview-pie-charts />

  <div class="row">
    <div class="col-xl-8">
      <div class="card">
        <div class="card-header border-0 align-items-center d-flex">
          <h4 class="card-title mb-0 flex-grow-1">Payment Activity</h4>
          <div>
            <button type="button" class="btn btn-soft-info btn-sm">ALL</button>
            <button type="button" class="btn btn-soft-info btn-sm">1M</button>
            <button type="button" class="btn btn-soft-info btn-sm">6M</button>
            <button type="button" class="btn btn-info btn-sm">1Y</button>
          </div>
        </div>
        <div class="card-body py-1">
          <div class="row gy-2">
            <div class="col-md-4">
              <h4 class="fs-22 mb-0">$23,590.00</h4>
            </div>
            <div class="col-md-8">
              <div class="d-flex main-chart justify-content-end">
                <div class="px-4 border-end">
                  <h4 class="text-primary fs-22 mb-0">
                    $584k
                    <span
                      class="text-muted d-inline-block fs-17 align-middle ms-0 ms-sm-2"
                      >Incomes</span
                    >
                  </h4>
                </div>
                <div class="ps-4">
                  <h4 class="text-primary fs-22 mb-0">
                    $324k
                    <span
                      class="text-muted d-inline-block fs-17 align-middle ms-0 ms-sm-2"
                      >Expenses</span
                    >
                  </h4>
                </div>
              </div>
            </div>
          </div>

          <div
            id="stacked-column-chart"
            class="apex-charts"
            data-colors='["--in-primary", "--in-light"]'
            dir="ltr"
          ></div>
        </div>
      </div>
    </div>

    <div class="col-xl-4">
      <div class="card">
        <div class="card-body">
          <div class="d-flex align-items-start">
            <div class="flex-grow-1 overflow-hidden">
              <h5 class="card-title mb-2 text-truncate">Invoices</h5>
            </div>
            <div class="flex-shrink-0 ms-2">
              <div class="dropdown">
                <a
                  class="text-reset"
                  href="#"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span class="fw-semibold text-uppercase fs-14">Trend:</span>
                  <span class="text-muted"
                    >Weekly<i class="las la-angle-down fs-12 ms-2"></i
                  ></span>
                </a>
                <div class="dropdown-menu dropdown-menu-end">
                  <a class="dropdown-item" href="#">Yearly</a>
                  <a class="dropdown-item" href="#">Monthly</a>
                  <a class="dropdown-item" href="#">Weekly</a>
                </div>
              </div>
            </div>
          </div>

          <div
            id="structure-widget"
            data-colors='["--in-primary", "--in-primary-rgb, 0.7", "--in-light"]'
            class="apex-charts"
            dir="ltr"
          ></div>

          <div class="px-2">
            <div
              class="structure-list d-flex justify-content-between border-bottom"
            >
              <p class="mb-0">
                <i class="las la-dot-circle fs-18 text-primary me-2"></i>
                Paid
              </p>
              <div>
                <span class="pe-2 pe-sm-5">$56,236</span>
                <span class="badge bg-primary"> + 0.2% </span>
              </div>
            </div>
            <div
              class="structure-list d-flex justify-content-between border-bottom"
            >
              <p class="mb-0">
                <i class="las la-dot-circle fs-18 text-primary me-2"></i>
                In Progress
              </p>
              <div>
                <span class="pe-2 pe-sm-5">$12,596</span>
                <span class="badge bg-primary"> - 0.7% </span>
              </div>
            </div>
            <div class="structure-list d-flex justify-content-between pb-0">
              <p class="mb-0">
                <i class="las la-dot-circle fs-18 text-primary me-2"></i>
                Refund
              </p>
              <div>
                <span class="pe-2 pe-sm-5">$1,568</span>
                <span class="badge bg-primary"> + 0.4% </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->

  <div class="row">
    <div class="col-xl-4">
      <div class="card">
        <div class="card-header border-0 align-items-center d-flex">
          <h4 class="card-title mb-0 flex-grow-1">
            Top Selling <br />
            Products
          </h4>
          <div class="dropdown">
            <a
              class="text-reset"
              href="#"
              id="dropdownMenuYear"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span class="fw-semibold text-uppercase fs-14">Trend: </span>
              <span class="text-muted"
                >Years<i class="las la-angle-down fs-12 ms-2"></i
              ></span>
            </a>
            <div
              class="dropdown-menu dropdown-menu-end"
              aria-labelledby="dropdownMenuYear"
            >
              <a class="dropdown-item" href="#">Monthly</a>
              <a class="dropdown-item" href="#">Yearly</a>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col">
              <div class="table-responsive">
                <table
                  class="table table-centered align-middle table-nowrap mb-0"
                >
                  <thead>
                    <tr class="text-uppercase">
                      <th style="width: 500px">Countrie</th>
                      <th style="width: 30%">Order</th>
                      <th style="width: 15%">Earning</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div class="d-flex align-items-center">
                          <img
                            src="/images/flags/us.svg"
                            class="rounded"
                            alt="user-image"
                            height="22"
                          />
                          <div class="flex-grow-1 ms-3">
                            <p class="mb-0 text-truncate">US</p>
                          </div>
                        </div>
                      </td>
                      <td>6253</td>
                      <td>$26,524</td>
                    </tr>

                    <tr>
                      <td>
                        <div class="d-flex align-items-center">
                          <img
                            src="/images/flags/italy.svg"
                            class="rounded"
                            alt="user-image"
                            height="22"
                          />
                          <div class="flex-grow-1 ms-3">
                            <p class="mb-0 text-truncate">Italy</p>
                          </div>
                        </div>
                      </td>
                      <td>5563</td>
                      <td>$32,562</td>
                    </tr>

                    <tr>
                      <td>
                        <div class="d-flex align-items-center">
                          <img
                            src="/images/flags/spain.svg"
                            class="rounded"
                            alt="user-image"
                            height="22"
                          />
                          <div class="flex-grow-1 ms-3">
                            <p class="mb-0 text-truncate">Spain</p>
                          </div>
                        </div>
                      </td>
                      <td>3258</td>
                      <td>$65,214</td>
                    </tr>

                    <tr>
                      <td>
                        <div class="d-flex align-items-center">
                          <img
                            src="/images/flags/french.svg"
                            class="rounded"
                            alt="user-image"
                            height="22"
                          />
                          <div class="flex-grow-1 ms-3">
                            <p class="mb-0 text-truncate">French</p>
                          </div>
                        </div>
                      </td>
                      <td>6325</td>
                      <td>$63,254</td>
                    </tr>

                    <tr>
                      <td>
                        <div class="d-flex align-items-center">
                          <img
                            src="/images/flags/russia.svg"
                            class="rounded"
                            alt="user-image"
                            height="22"
                          />
                          <div class="flex-grow-1 ms-3">
                            <p class="mb-0 text-truncate">Russia</p>
                          </div>
                        </div>
                      </td>
                      <td>8652</td>
                      <td>$53,621</td>
                    </tr>

                    <tr>
                      <td>
                        <div class="d-flex align-items-center">
                          <img
                            src="/images/flags/ae.svg"
                            class="rounded"
                            alt="user-image"
                            height="22"
                          />
                          <div class="flex-grow-1 ms-3">
                            <p class="mb-0 text-truncate">Arabic</p>
                          </div>
                        </div>
                      </td>
                      <td>4256</td>
                      <td>$86,526</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-4">
      <div class="card">
        <div class="card-header border-0 align-items-center d-flex pb-2">
          <h4 class="card-title mb-0 flex-grow-1">Payment Overview</h4>
          <div>
            <div class="dropdown">
              <a
                class="text-reset"
                href="#"
                id="dropdownMenuYear"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span class="fw-semibold text-uppercase fs-14">Trend: </span>
                <span class="text-muted"
                  >Monthly<i class="las la-angle-down fs-12 ms-2"></i
                ></span>
              </a>
              <div
                class="dropdown-menu dropdown-menu-end"
                aria-labelledby="dropdownMenuYear"
              >
                <a class="dropdown-item" href="#">Monthly</a>
                <a class="dropdown-item" href="#">Yearly</a>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body pt-0">
          <div
            id="payment-overview"
            data-colors='["--in-primary", "--in-light"]'
            class="apex-charts"
            dir="ltr"
          ></div>
          <div class="row mt-3 text-center">
            <div class="col-6 border-end">
              <div class="my-1">
                <p class="text-muted text-truncate mb-2">Products Sold</p>
                <h4 class="mt-2 mb-0 fs-20">154</h4>
              </div>
            </div>
            <div class="col-6">
              <div class="my-1">
                <p class="text-muted text-truncate mb-2">
                  Products In Progress
                </p>
                <h4 class="mt-2 mb-0 fs-20">14</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-4">
      <div class="card">
        <div class="card-header border-0 align-items-center d-flex">
          <h4 class="card-title mb-0 flex-grow-1">
            Top Profitable <br />
            Products
          </h4>
          <div class="dropdown">
            <a
              class="text-reset"
              href="#"
              id="dropdownMenuYear"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span class="fw-semibold text-uppercase fs-14">Trend: </span>
              <span class="text-muted"
                >Years<i class="las la-angle-down fs-12 ms-2"></i
              ></span>
            </a>
            <div
              class="dropdown-menu dropdown-menu-end"
              aria-labelledby="dropdownMenuYear"
            >
              <a class="dropdown-item" href="#">Monthly</a>
              <a class="dropdown-item" href="#">Yearly</a>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col">
              <div class="table-responsive">
                <table
                  class="table table-centered align-middle table-nowrap mb-0"
                >
                  <thead>
                    <tr class="text-uppercase">
                      <th style="width: 500px">Countrie</th>
                      <th style="width: 30%">Order</th>
                      <th style="width: 15%">Earning</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div class="d-flex align-items-center">
                          <img
                            src="/images/flags/us.svg"
                            class="rounded"
                            alt="user-image"
                            height="22"
                          />
                          <div class="flex-grow-1 ms-3">
                            <p class="mb-0 text-truncate">US</p>
                          </div>
                        </div>
                      </td>
                      <td>6253</td>
                      <td>$26,524</td>
                    </tr>

                    <tr>
                      <td>
                        <div class="d-flex align-items-center">
                          <img
                            src="/images/flags/italy.svg"
                            class="rounded"
                            alt="user-image"
                            height="22"
                          />
                          <div class="flex-grow-1 ms-3">
                            <p class="mb-0 text-truncate">Italy</p>
                          </div>
                        </div>
                      </td>
                      <td>5563</td>
                      <td>$32,562</td>
                    </tr>

                    <tr>
                      <td>
                        <div class="d-flex align-items-center">
                          <img
                            src="/images/flags/spain.svg"
                            class="rounded"
                            alt="user-image"
                            height="22"
                          />
                          <div class="flex-grow-1 ms-3">
                            <p class="mb-0 text-truncate">Spain</p>
                          </div>
                        </div>
                      </td>
                      <td>3258</td>
                      <td>$65,214</td>
                    </tr>

                    <tr>
                      <td>
                        <div class="d-flex align-items-center">
                          <img
                            src="/images/flags/french.svg"
                            class="rounded"
                            alt="user-image"
                            height="22"
                          />
                          <div class="flex-grow-1 ms-3">
                            <p class="mb-0 text-truncate">French</p>
                          </div>
                        </div>
                      </td>
                      <td>6325</td>
                      <td>$63,254</td>
                    </tr>

                    <tr>
                      <td>
                        <div class="d-flex align-items-center">
                          <img
                            src="/images/flags/russia.svg"
                            class="rounded"
                            alt="user-image"
                            height="22"
                          />
                          <div class="flex-grow-1 ms-3">
                            <p class="mb-0 text-truncate">Russia</p>
                          </div>
                        </div>
                      </td>
                      <td>8652</td>
                      <td>$53,621</td>
                    </tr>

                    <tr>
                      <td>
                        <div class="d-flex align-items-center">
                          <img
                            src="/images/flags/ae.svg"
                            class="rounded"
                            alt="user-image"
                            height="22"
                          />
                          <div class="flex-grow-1 ms-3">
                            <p class="mb-0 text-truncate">Arabic</p>
                          </div>
                        </div>
                      </td>
                      <td>4256</td>
                      <td>$86,526</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</template>

<script>
import PageTitleComponent from "@/components/shared/PageTitle";
import OverviewPieCharts from "@/components/utils/charts/OverviewPieCharts";
export default {
  name: "DashboardView",
  components: { OverviewPieCharts, PageTitle: PageTitleComponent },
  created() {
    this.$router.push("/invoices");
  },
};
</script>
