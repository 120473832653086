import axios from "axios";
import { CONFIG } from "@/constants/config.js";

const billersBackend = CONFIG.BACKEND_URL + "supplier";

const state = {
  biller: {},
  billers: [],
  loading: false,
  error: "",
  currentPage: 1,
  numPages: 1,
  totalRows: 0,
};

const config = () => ({
  headers: {
    Token: localStorage.getItem("accessToken"),
  },
});

const getters = {
  biller: (state) => state.biller,
  allBillers: (state) => state.billers,
  billersLoading: (state) => state.loading,
  billersError: (state) => state.error,
  billersCurrentPage: (state) => state.currentPage,
  billersNumPages: (state) => state.numPages,
  billersTotalRows: (state) => state.totalRows,
  getBillerById: (state) => (id) => {
    return state.billers.find((biller) => biller.id === id);
  },
};

const actions = {
  async getBiller({ commit /*, dispatch */ }, id) {
    commit("setLoading", true);
    await axios
      .post(`${billersBackend}/${id}/`, null, config())
      .then(async (response) => {
        commit("setLoading", false);
        await commit("setBiller", response.data);
        commit("setError", "");
      })
      .catch(async (error) => {
        if (error.response) {
          await commit("reset");
          commit("setError", error.response.data.message);
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
          console.log(error);
        }
      });
  },
  async getBillers({ commit /*, dispatch */ }, filter = null) {
    commit("setLoading", true);
    await axios
      .post(`${billersBackend}/all/`, filter, config())
      .then(async (response) => {
        commit("setLoading", false);
        await commit("setBillers", response.data);
        commit("setError", "");
      })
      .catch(async (error) => {
        if (error.response) {
          await commit("reset");
          commit("setError", error.response.data.message);
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
          console.log(error);
        }
      });
  },
  async getDeletedBillers({ commit /*, dispatch */ }, filter = null) {
    commit("setLoading", true);
    await axios
      .post(`${billersBackend}/all/deleted/`, filter, config())
      .then(async (response) => {
        commit("setLoading", false);
        await commit("setBillers", response.data);
        commit("setError", "");
      })
      .catch(async (error) => {
        if (error.response) {
          await commit("reset");
          commit("setError", error.response.data.message);
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
          console.log(error);
        }
      });
  },
  async addBiller({ commit /*, dispatch */ }, biller) {
    commit("setLoading", true);
    await axios
      .post(`${billersBackend}/new/`, biller, config())
      .then(async () => {
        commit("setLoading", false);
        commit("setError", "");
      })
      .catch(async (error) => {
        if (error.response) {
          await commit("reset");
          commit("setError", error.response.data.message);
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
          console.log(error);
        }
      });
  },
  // eslint-disable-next-line no-unused-vars
  async editBiller({ commit, dispatch }, biller) {
    commit("setLoading", true);
    await axios
      .patch(`${billersBackend}/update/`, biller, config())
      .then(async () => {
        commit("setLoading", false);
        commit("setError", "");
      })
      .catch(async (error) => {
        if (error.response) {
          await commit("reset");
          commit("setError", error.response.data.message);
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
          console.log(error);
        }
      });
  },
  // eslint-disable-next-line no-unused-vars
  async deleteBiller({ commit, dispatch }, id) {
    commit("setLoading", true);
    await axios
      .delete(`${billersBackend}/delete/${id}/`, config())
      .then(async () => {
        commit("setLoading", false);
        commit("setError", "");
      })
      .catch(async (error) => {
        if (error.response) {
          await commit("reset");
          commit("setError", error.response.data.message);
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
          console.log(error);
        }
      });
  },
  // eslint-disable-next-line no-unused-vars
  async recoverBiller({ commit, dispatch }, id) {
    commit("setLoading", true);
    await axios
      .post(`${billersBackend}/recover/${id}/`, config())
      .then(async () => {
        commit("setLoading", false);
        commit("setError", "");
        // dispatch(
        //   "notification/AddNotification",
        //   { type: "success", message: "Product Edited Successfully Created" },
        //   { root: true }
        // );
      })
      .catch((error) => {
        if (error.response.data.message) {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: error.message },
          //   { root: true }
          // );
        }
        commit("setLoading", false);
        commit("setError", error.data.message);
        throw error;
      });
  },
  async resetBiller({ commit }) {
    await commit("reset");
  },
};

const mutations = {
  addBiller(state, biller) {
    state.billers.push(biller);
  },
  reset(state) {
    state.biller = {};
    state.billers = [];
    state.loading = false;
    state.error = "";
    state.currentPage = 1;
    state.numPages = 1;
    state.totalRows = 0;
  },
  setBiller(state, response) {
    state.biller = response;
  },
  setBillers(state, response) {
    state.billers = response.suppliers;
    state.currentPage = response.currentPage;
    state.numPages = response.numPages;
    state.totalRows = response.totalRows;
  },
  editBiller(state, editedBiller) {
    const index = state.billers.findIndex(
      (biller) => biller.id === editedBiller.id
    );
    if (index !== -1) {
      state.billers[index] = { ...editedBiller };
    }
  },
  deleteBiller: (state, id) => {
    const index = state.billers.findIndex((biller) => biller.id === id);
    if (index !== -1) {
      state.billers.splice(index, 1);
    }
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setError(state, error) {
    state.error = error;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
