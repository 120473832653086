<template>
  <div class="row">
    <div class="col-sm-auto col-md">
      <div class="card">
        <div class="card-body">
          <div class="p-2">
            <form :class="{ 'disabled-form': disabled }">
              <div class="mb-3">
                <label class="form-label" for="productname">Product Name</label>
                <input
                  id="productname"
                  placeholder="Enter Product Name"
                  v-model="updatedProduct.name"
                  type="text"
                  class="form-control"
                  :disabled="disabled"
                />
              </div>
              <div class="mb-3">
                <label class="form-label" for="unitQuantity">
                  Product Unit Quantity
                </label>
                <input
                  id="unitQuantity"
                  placeholder="Enter Product Unit Quantity"
                  v-model="updatedProduct.unitQuantity"
                  type="number"
                  class="form-control"
                  :disabled="disabled"
                />
              </div>
              <div class="mb-3">
                <label for="choices-single-default" class="form-label">
                  Barcode Symbology
                </label>
                <select
                  class="form-control"
                  data-trigger
                  placeholder="Enter Product Name"
                  v-model="updatedProduct.barcodeSymbology"
                  id="choices-single-category"
                  :disabled="disabled"
                >
                  <option value="upca">UPC-A</option>
                  <option value="code128">Code128</option>
                  <option value="ean8">EAN8</option>
                  <option value="ean13">EAN13</option>
                </select>
              </div>
              <div class="mb-3">
                <label class="form-label" for="productnumber">
                  Item Barcode Number
                </label>
                <div class="input-group" bis_skin_checked="1">
                  <input
                    v-model="updatedProduct.barcode"
                    type="text"
                    class="form-control"
                    placeholder="Click the button to generate barcode"
                    :disabled="updatedProduct.barcodeSymbology === ''"
                  />
                  <button
                    v-if="!disabled"
                    class="btn btn-success"
                    type="button"
                    @click="generateBarcode"
                  >
                    <i class="ri-refresh-line align-bottom me-1"></i>
                  </button>
                </div>
              </div>
              <div class="mb-3">
                <label class="form-label" for="productnumber">
                  Box Barcode Number
                </label>
                <input
                  v-model="updatedProduct.boxBarcode"
                  type="text"
                  class="form-control"
                  placeholder="Enter the box barcode"
                />
              </div>
              <div class="row">
                <div class="mb-3">
                  <label for="category" class="form-label"> Category </label>
                  <DropdownInput
                    id="category"
                    type="text"
                    placeholder="Enter the category"
                    :chosen="{ value: updatedProduct.category }"
                    :options="categories"
                    :disabled="disabled"
                    @choice="setCategory"
                  />
                </div>
              </div>
              <div class="mb-3">
                <label class="form-label" for="costPrice">
                  Product Cost Price
                </label>
                <input
                  id="costPrice"
                  placeholder="Enter the Cost Price"
                  v-model="updatedProduct.costPrice"
                  type="number"
                  step="0.01"
                  class="form-control"
                  :disabled="disabled || updatedProduct.productGroupID !== 0"
                />
              </div>
              <div class="mb-3">
                <label class="form-label" for="sellPrice">
                  Product Selling Price
                </label>
                <input
                  id="sellPrice"
                  placeholder="Enter Selling Price"
                  v-model="updatedProduct.sellingPrice"
                  type="number"
                  step="0.01"
                  class="form-control"
                  :disabled="disabled || updatedProduct.productGroupID !== 0"
                />
              </div>
              <div class="mb-3">
                <label class="form-label" for="suggestedRetailPrice">
                  Product Suggested Retail Price
                </label>
                <input
                  id="suggestedRetailPrice"
                  placeholder="Enter Suggested Retail Price"
                  v-model="updatedProduct.suggestedRetailPrice"
                  type="number"
                  step="0.01"
                  class="form-control"
                  :disabled="disabled || updatedProduct.productGroupID !== 0"
                />
              </div>
              <div class="mb-3">
                <label class="form-label" for="quantitySold">
                  Product Quantity Sold
                </label>
                <input
                  id="quantitySold"
                  placeholder="Enter Quantity Sold"
                  v-model="updatedProduct.quantitySold"
                  type="number"
                  class="form-control"
                />
              </div>
              <div class="hstack gap-2 mt-4">
                <button
                  v-if="!disabled"
                  type="button"
                  @click="submit"
                  class="btn btn-primary"
                >
                  Save
                </button>
                <button
                  v-if="disabled"
                  type="button"
                  class="btn btn-primary"
                  @click="edit"
                >
                  Edit
                </button>
                <button type="button" class="btn btn-light" @click="discard">
                  Back
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SETTINGS } from "@/constants/config";
import DropdownInput from "@/components/utils/DropdownInput";

export default {
  name: "ProductForm",
  components: { DropdownInput },
  props: {
    product: {
      type: Object,
      required: true,
    },
    actionType: {
      type: String,
      required: true,
    },
    submitFunction: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      updatedProduct: {
        ...this.product,
        quantitySold:
          "quantitySold" in this.product && this.product.quantitySold !== null
            ? this.product.quantitySold
            : 0,
        barcodeSymbology:
          "barcodeSymbology" in this.product &&
          this.product.barcodeSymbology !== null
            ? this.product.barcodeSymbology
            : "upca",
        productGroupID:
          "productGroupID" in this.product &&
          this.product.productGroupID !== null
            ? this.product.productGroupID
            : 0,
      },
      chosenCategory: {},
    };
  },
  methods: {
    submit() {
      if (!this.disabled) {
        this.updatedProduct.category = this.chosenCategory.value;
        this.submitFunction(this.updatedProduct);
      }
    },
    discard() {
      this.updatedProduct = { ...this.product };
      this.$router.push("/products/");
    },
    edit() {
      this.$router.push("/products/edit/" + this.product.id);
    },
    async generateBarcode() {
      await this.$store.dispatch("generateBarcode", this.updatedProduct);
    },
    async setCategory(value, id) {
      let filter = {
        resultsPerPage: SETTINGS.resultsPerInput,
        searchQuery: value,
      };
      await this.$store.dispatch("getCategories", filter);
      this.chosenCategory.value = value;
      if (id > 0) {
        this.chosenCategory.value = this.categories.find(
          (category) => category.id === id
        );
      }
    },
  },
  computed: {
    categories() {
      return this.$store.getters.categories;
    },
    disabled() {
      return this.actionType === "view";
    },
    barcode() {
      return this.$store.getters.barcode;
    },
  },
  async created() {
    let filter = {
      resultsPerPage: SETTINGS.resultsPerInput,
    };
    await this.$store.dispatch("getCategories", filter);
  },
};
</script>

<style scoped>
.disabled-form input {
  pointer-events: none;
  opacity: 0.6;
}
</style>
