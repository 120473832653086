<template>
  <page-title
    pageTitle="Customer Price Group"
    title="Edit Customer Price Group"
  />
  <price-group-form
    v-if="priceGroup"
    :priceGroup="priceGroup"
    :submitFunction="editPriceGroup"
  />
  <div v-else class="card">
    <LoadingModal v-if="loading" />
    <div v-else-if="error" class="card-body">
      {{ error }}
    </div>
    <div v-else class="card-body">The Customer Price Group doesn't exist</div>
    <div class="gap-2 mt-4 card-body">
      <button type="button" class="btn btn-light" @click="this.$router.go(-1)">
        Back
      </button>
    </div>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/shared/PageTitle";
import PriceGroupFormComponent from "@/components/priceGroup/PriceGroupForm";
import LoadingModalComponent from "@/components/utils/LoadingModal";

export default {
  name: "EditPriceGroupView",
  components: {
    PageTitle: PageTitleComponent,
    PriceGroupForm: PriceGroupFormComponent,
    LoadingModal: LoadingModalComponent,
  },
  data() {
    return {
      id: -1,
      priceGroup: null,
    };
  },
  computed: {
    error() {
      return this.$store.getters.priceGroupsError;
    },
    loading() {
      return this.$store.getters.priceGroupsLoading;
    },
  },
  methods: {
    async editPriceGroup(priceGroup) {
      await this.$store.dispatch("editPriceGroup", priceGroup);
      this.$router.push("/priceGroups");
    },
  },
  async created() {
    this.id = parseInt(this.$route.params.id);
    await this.$store.dispatch("getPriceGroups");
    this.priceGroup = this.$store.getters.getPriceGroupByID(this.id);
  },
};
</script>

<style scoped></style>
