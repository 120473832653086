<template>
  <page-title pageTitle="Customer Price Group" :title="priceGroupName" />
  <price-group-item-form
    v-if="priceGroupItem"
    :priceGroupItem="priceGroupItem"
    :disabled-list="
      new Set(['barcode', 'productName', 'costPrice', 'retailPrice'])
    "
    :submitFunction="editPriceGroupItem"
  />
  <div v-else class="card">
    <LoadingModal v-if="loading" />
    <div v-else-if="error" class="card-body">
      {{ error }}
    </div>
    <div v-else class="card-body">
      The Product is not in this customer price group
    </div>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/shared/PageTitle";
import PriceGroupItemFormComponent from "@/components/priceGroup/PriceGroupItemForm";
import LoadingModalComponent from "@/components/utils/LoadingModal";

export default {
  name: "EditPriceGroupView",
  components: {
    PageTitle: PageTitleComponent,
    PriceGroupItemForm: PriceGroupItemFormComponent,
    LoadingModal: LoadingModalComponent,
  },
  data() {
    return {
      id: -1,
      itemID: -1,
      priceGroupItem: null,
    };
  },
  computed: {
    error() {
      return this.$store.getters.priceGroupsError;
    },
    loading() {
      return this.$store.getters.priceGroupsLoading;
    },
    priceGroupName() {
      if (this.$store.getters.priceGroupSummary.name) {
        return this.$store.getters.priceGroupSummary.name;
      }
      return "Customer Price Group";
    },
  },
  methods: {
    async editPriceGroupItem(priceGroupItem) {
      await this.$store.dispatch("editPriceGroupItem", priceGroupItem);
      this.$router.push(`/priceGroups/${this.id}`);
    },
  },
  async created() {
    this.id = parseInt(this.$route.params.id);
    this.itemID = parseInt(this.$route.params.itemID);
    await this.$store.dispatch("getPriceGroup", this.id);
    await this.$store.dispatch("getPriceGroupItem", this.itemID);
    this.priceGroupItem = this.$store.getters.priceGroupItem;
    await this.$store.dispatch(
      "getProduct",
      this.$store.getters.priceGroupItem.productID
    );
  },
};
</script>
