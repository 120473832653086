<template>
  <page-title pageTitle="User" title="New User" />
  <user-form :user="{}" :submitFunction="addUser" action-type="new" />
</template>

<script>
import PageTitleComponent from "@/components/shared/PageTitle";
import UserFormComponent from "@/components/users/UserForm";

export default {
  name: "NewUserView",
  components: {
    PageTitle: PageTitleComponent,
    UserForm: UserFormComponent,
  },
  data() {
    return {
      id: -1,
      product: null,
    };
  },
  methods: {
    async addUser(user) {
      await this.$store.dispatch("signUp", user);
      this.$router.push("/users");
    },
  },
};
</script>

<style scoped></style>
