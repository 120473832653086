<template>
  <div class="row">
    <div class="col-xl-12">
      <div class="card">
        <div class="card-body">
          <form
            @submit.prevent="submit(updatedBiller)"
            :class="{ 'disabled-form': disabled }"
          >
            <div class="p-2">
              <div class="mb-3">
                <label class="form-label" for="company">Company</label>
                <input
                  id="company"
                  name="company"
                  placeholder="Enter Company Name"
                  type="text"
                  class="form-control"
                  v-model="updatedBiller.company"
                  :disabled="disabled"
                />
              </div>

              <div class="mb-3">
                <label class="form-label" for="email">Email</label>
                <input
                  id="email"
                  name="email"
                  placeholder="Enter Email"
                  type="email"
                  class="form-control"
                  v-model="updatedBiller.email"
                  :disabled="disabled"
                />
              </div>

              <div class="mb-3">
                <label class="form-label" for="phone">Phone</label>
                <input
                  id="phone"
                  name="phone"
                  placeholder="Enter Phone"
                  type="tel"
                  class="form-control"
                  v-model="updatedBiller.phone"
                  :disabled="disabled"
                />
              </div>

              <div class="mb-3">
                <label class="form-label" for="address">Address</label>
                <input
                  id="address"
                  name="address"
                  placeholder="Enter Street Address"
                  type="text"
                  class="form-control"
                  v-model="updatedBiller.address"
                  :disabled="disabled"
                />
              </div>

              <div class="mb-3">
                <label class="form-label" for="city">City</label>
                <input
                  id="city"
                  name="city"
                  placeholder="Enter City"
                  type="text"
                  class="form-control"
                  v-model="updatedBiller.city"
                  :disabled="disabled"
                />
              </div>

              <div class="mb-3">
                <label class="form-label" for="state">State</label>
                <input
                  id="state"
                  name="state"
                  placeholder="Enter State"
                  type="text"
                  class="form-control"
                  v-model="updatedBiller.state"
                  :disabled="disabled"
                />
              </div>

              <div class="mb-3">
                <label class="form-label" for="postalCode">Postal Code</label>
                <input
                  id="postalCode"
                  name="postalCode"
                  placeholder="Enter Postal Code"
                  type="text"
                  class="form-control"
                  v-model="updatedBiller.postalCode"
                  :disabled="disabled"
                />
              </div>

              <div class="hstack gap-2 mt-4">
                <button v-if="!disabled" type="submit" class="btn btn-primary">
                  Save
                </button>
                <button
                  v-if="disabled"
                  type="button"
                  class="btn btn-primary"
                  @click="edit"
                >
                  Edit
                </button>
                <button type="button" class="btn btn-light" @click="discard">
                  Back
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BillerForm",
  props: {
    biller: {
      type: Object,
      required: true,
    },
    submitFunction: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      updatedBiller: { ...this.biller },
    };
  },
  methods: {
    submit() {
      if (!this.disabled) {
        this.submitFunction(this.updatedBiller);
      }
    },
    discard() {
      this.updatedBiller = { ...this.biller };
      this.$router.push("/billers/");
    },
    edit() {
      this.$router.push("/billers/edit/" + this.biller.id);
    },
  },
  computed: {
    disabled() {
      return typeof this.submitFunction !== "function";
    },
  },
};
</script>

<style scoped>
.disabled-form input {
  pointer-events: none;
  opacity: 0.6;
}
</style>
