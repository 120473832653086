<template>
  <label for="companyAddress">Customer *</label>
  <AutoCompleteInput
    type="text"
    placeholder="Choose Customer"
    :chosen="buyer"
    :options="buyers"
    @choice="setBuyerID"
    :disabled="status !== 'NEW'"
  />
</template>

<script>
import AutoCompleteInput from "@/components/utils/AutoCompleteInput";
import { SETTINGS } from "@/constants/config";
export default {
  name: "BuyerInputGroup",
  components: { AutoCompleteInput },
  data() {
    return {
      buyer: {},
    };
  },
  computed: {
    status() {
      return this.$store.getters.invoiceSummary.status;
    },
    buyers() {
      return this.$store.getters.allBuyers.map((item) => {
        // Check if item.name is null or empty
        const name = item.name ? ` (${item.name})` : "";
        // Concatenate company and name if name is not null/empty
        return {
          ...item,
          name: item.company + name,
        };
      });
    },
  },
  methods: {
    async setBuyerID(value, id) {
      let filter = {
        resultsPerPage: SETTINGS.resultsPerInputCustomer,
        searchQuery: value,
      };
      await this.$store.dispatch("getBuyers", filter);
      this.buyer.value = value;
      this.buyer.id = id;
      if (id > 0) {
        await this.$store.dispatch("editInvoice", {
          id: this.$store.getters.invoiceSummary.id,
          customerID: id,
        });
        await this.$store.dispatch(
          "getInvoice",
          this.$store.getters.invoiceSummary.id
        );
        await this.$store.dispatch("getBuyer", id);
      }
    },
  },
  async created() {
    // Initialize buyer object
    if (!this.$store.getters.invoiceSummary.customer) {
      this.buyer = { value: "", id: -1 };
    } else {
      this.buyer = {
        value: this.$store.getters.invoiceSummary.customer,
        id: this.$store.getters.invoiceSummary.customerID,
      };
    }

    // Retrieve buyers based on conditions
    let filter = {
      resultsPerPage: SETTINGS.resultsPerInputCustomer,
    };
    await this.$store.dispatch("getBuyers", filter);

    // If a buyer ID exists, retrieve specific buyer details
    if (this.buyer.id && this.buyer.id > 0) {
      await this.$store.dispatch("getBuyer", this.buyer.id);
    }
  },
};
</script>

<style scoped></style>
