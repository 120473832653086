<template>
  <page-title pageTitle="Product Price Group" title="New Product Price Group" />
  <product-group-form :productGroup="{}" :submitFunction="addProductGroup" />
</template>

<script>
import PageTitleComponent from "@/components/shared/PageTitle";
import ProductGroupFormComponent from "@/components/productGroup/ProductGroupForm";

export default {
  name: "NewProductGroupView",
  components: {
    PageTitle: PageTitleComponent,
    ProductGroupForm: ProductGroupFormComponent,
  },
  data() {
    return {
      id: -1,
      priceGroup: null,
    };
  },
  methods: {
    async addProductGroup(productGroup) {
      await this.$store.dispatch("addProductGroup", productGroup);
      this.$router.push("/productGroups");
    },
  },
};
</script>

<style scoped></style>
