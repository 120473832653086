<template>
  <page-title-component pageTitle="Settings" title="Settings" />
  <div class="row">
    <div class="col-xl-12">
      <div class="card">
        <div class="card-header">
          <h6 class="card-title mb-0">General Settings</h6>
        </div>
        <div class="card-body">
          <numeric-setting
            setting-name="Results Per Page"
            inputName="resultsPerPage"
          />
          <numeric-setting
            setting-name="Results Per Input"
            inputName="resultsPerInput"
          />
          <numeric-setting
            setting-name="Pages Displayed"
            inputName="pagesDisplayed"
          />
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h6 class="card-title mb-0">Invoice Settings</h6>
        </div>
        <div class="card-body">
          <switch-setting
            setting-name="Results Per Page"
            inputName="resultsPerPage"
          />
          <numeric-setting
            setting-name="Results Per Input Invoices"
            inputName="resultsPerInputInvoices"
          />
          <switch-setting
            setting-name="Show Suggested Analysis by Default"
            inputName="showSuggestedAnalysisByDefault"
          />
          <switch-setting
            setting-name="Show Individual Margin by Default"
            inputName="showIndividualMarginByDefault"
          />
          <switch-setting
            setting-name="Show Damaged Goods by Default"
            inputName="showDamagedGoodsByDefault"
          />
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h6 class="card-title mb-0">Customer Settings</h6>
        </div>
        <div class="card-body">
          <numeric-setting
            setting-name="Results Per Input Customer"
            inputName="resultsPerInputCustomer"
          />
        </div>
      </div>
    </div>
  </div>
  <!--  <buyer-form v-if="buyer.id" :buyer="buyer" :submitFunction="editBuyer" />-->
  <!--  <div v-else class="card">-->
  <!--    <LoadingModal v-if="loading" />-->
  <!--    <div v-else-if="error" class="card-body">-->
  <!--      {{ error }}-->
  <!--    </div>-->
  <!--    <div v-else class="card-body">The customer doesn't exist</div>-->
  <!--  </div>-->
</template>

<script>
import PageTitleComponent from "@/components/shared/PageTitle";
import SwitchSetting from "@/components/settings/switchSetting";
import NumericSetting from "@/components/settings/numericSetting";
export default {
  name: "SettingsView",
  components: { NumericSetting, SwitchSetting, PageTitleComponent },
};
</script>

<style scoped></style>
