<template>
  <div class="modal-backdrop fade show" bis_skin_checked="1"></div>
  <div
    class="modal fade show"
    id="staticBackdrop"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    bis_skin_checked="1"
    style="display: block; padding-left: 0px"
    aria-modal="true"
    role="dialog"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
      bis_skin_checked="1"
    >
      <div class="modal-content" bis_skin_checked="1">
        <div class="modal-body text-center p-5" bis_skin_checked="1">
          <div bis_skin_checked="1">
            <h4 class="mb-3">Loading</h4>
            <p class="text-muted mb-4">The page is loading, please wait.</p>
            <div class="progress">
              <div
                class="progress-bar progress-bar-striped progress-bar-animated"
                role="progressbar"
                style="width: 100%"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingModal",
};
</script>

<style scoped></style>
