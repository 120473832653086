<template>
  <loading-modal />
</template>

<script>
import LoadingModal from "@/components/utils/LoadingModal";
export default {
  name: "NewInvoiceView",
  components: { LoadingModal },
  created() {
    this.$store.dispatch("addInvoice");
  },
};
</script>

<style scoped></style>
