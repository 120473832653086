<template>
  <div class="d-flex justify-content-start align-items-center m-4">
    <h6 class="card-title mb-0 mx-2">{{ settingName }}</h6>
    <div class="input-step">
      <button type="button" class="minus">–</button>
      <input
        type="number"
        class="product-quantity"
        value="2"
        min="0"
        max="100"
        readonly=""
      />
      <button type="button" class="plus">+</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "numericSetting",
  props: {
    settingName: {
      type: String,
      required: true,
    },
    inputName: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped></style>
