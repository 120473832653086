import axios from "axios";
import { CONFIG } from "@/constants/config.js";

const paymentHistoryBackend = CONFIG.BACKEND_URL + "payment";

const state = {
  paymentHistory: [],
  loading: false,
  error: "",
  currentPage: 1,
  numPages: 1,
  totalRows: 0,
};

const config = () => ({
  headers: {
    Token: localStorage.getItem("accessToken"),
  },
});

const getters = {
  paymentHistory: (state) => state.paymentHistory,
  paymentHistoryLoading: (state) => state.loading,
  paymentHistoryError: (state) => state.error,
  paymentHistoryCurrentPage: (state) => state.currentPage,
  paymentHistoryNumPages: (state) => state.numPages,
  paymentHistoryTotalRows: (state) => state.totalRows,
};

const actions = {
  async getCustomerPaymentHistory({ commit }, { id, filter = null }) {
    commit("setLoading", true);
    await axios
      .post(`${paymentHistoryBackend}/customer/${id}/`, filter, config())
      .then(async (response) => {
        commit("setLoading", false);
        await commit("setPaymentHistory", response.data);
        commit("setError", "");
      })
      .catch(async (error) => {
        if (error.response) {
          await commit("reset");
          commit("setError", error.response.data.message);
        } else {
          console.log(error);
        }
      });
  },
  async getInvoicePaymentHistory({ commit }, { id, filter = null }) {
    commit("setLoading", true);
    await axios
      .post(`${paymentHistoryBackend}/invoice/${id}/`, filter, config())
      .then(async (response) => {
        commit("setLoading", false);
        await commit("setPaymentHistory", response.data);
        commit("setError", "");
      })
      .catch(async (error) => {
        if (error.response) {
          await commit("reset");
          commit("setError", error.response.data.message);
        } else {
          console.log(error);
        }
      });
  },
  async addPayment({ commit }, payment) {
    commit("setLoading", true);
    await axios
      .post(`${paymentHistoryBackend}/insert/`, payment, config())
      .then(async () => {
        commit("setLoading", false);
        commit("setError", "");
      })
      .catch((error) => {
        commit("setLoading", false);
        commit("setError", error.data.message);
        throw error;
      });
  },
  async updatePayment({ commit }, payment) {
    commit("setLoading", true);
    await axios
      .patch(`${paymentHistoryBackend}/update/`, payment, config())
      .then(async () => {
        commit("setLoading", false);
        commit("setError", "");
      })
      .catch((error) => {
        commit("setLoading", false);
        commit("setError", error.data.message);
        throw error;
      });
  },
  async deletePayment({ commit }, id) {
    commit("setLoading", true);
    await axios
      .delete(`${paymentHistoryBackend}/delete/${id}/`, config())
      .then(async () => {
        commit("setLoading", false);
        commit("setError", "");
      })
      .catch((error) => {
        commit("setLoading", false);
        commit("setError", error.data.message);
        throw error;
      });
  },
};

const mutations = {
  reset(state) {
    state.paymentHistory = [];
    state.loading = false;
    state.error = "";
    state.currentPage = 1;
    state.numPages = 1;
    state.totalRows = 0;
  },
  setPaymentHistory(state, response) {
    state.paymentHistory = response.paymentHistory;
    state.currentPage = response.currentPage;
    state.numPages = response.numPages;
    state.totalRows = response.totalRows;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setError(state, error) {
    state.error = error;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
