import axios from "axios";
import { CONFIG } from "@/constants/config.js";
import router from "@/router";

const productsBackend = CONFIG.BACKEND_URL + "product";

const state = {
  product: {},
  categories: [],
  products: [],
  filters: [],
  loading: false,
  error: "",
  currentPage: 1,
  numPages: 1,
  totalRows: 0,
};

const config = () => ({
  headers: {
    Token: localStorage.getItem("accessToken"),
  },
});

const getters = {
  product: (state) => state.product,
  barcode: (state) => state.barcode,
  categories: (state) => state.categories,
  allProducts: (state) => state.products,
  filters: (state) => state.filters,
  productsLoading: (state) => state.loading,
  productsError: (state) => state.error,
  productsCurrentPage: (state) => state.currentPage,
  productsNumPages: (state) => state.numPages,
  productsTotalRows: (state) => state.totalRows,
};

const actions = {
  async getProduct({ commit /*, dispatch */ }, id) {
    commit("setLoading", true);
    await axios
      .post(`${productsBackend}/${id}/`, null, config())
      .then(async (response) => {
        commit("setLoading", false);
        await commit("setProduct", response.data);
        commit("setError", "");
      })
      .catch(async (error) => {
        if (error.response) {
          await commit("reset");
          commit("setError", error.response.data.message);
          alert("Product does not exist");
          await router.push(`/products`);
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
          console.log(error);
        }
      });
  },
  async getCategories({ commit /*, dispatch */ }, filter = null) {
    await axios
      .post(`${productsBackend}/categories/`, filter, config())
      .then(async (response) => {
        await commit("setCategories", response.data);
      })
      .catch(async (error) => {
        console.log(error);
      });
  },
  async getProducts({ commit /*, dispatch */ }, filter = null) {
    commit("setLoading", true);
    await axios
      .post(`${productsBackend}/all/`, filter, config())
      .then(async (response) => {
        commit("setLoading", false);
        await commit("setProducts", response.data);
        commit("setError", "");
      })
      .catch(async (error) => {
        if (error.response) {
          await commit("reset");
          commit("setError", error.response.data.message);
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
          console.log(error);
        }
      });
  },
  async getDeletedProducts({ commit /*, dispatch */ }, filter = null) {
    commit("setLoading", true);
    await axios
      .post(`${productsBackend}/all/deleted/`, filter, config())
      .then(async (response) => {
        commit("setLoading", false);
        await commit("setProducts", response.data);
        commit("setError", "");
      })
      .catch(async (error) => {
        if (error.response) {
          await commit("reset");
          commit("setError", error.response.data.message);
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
          console.log(error);
        }
      });
  },
  async getFilters({ commit /*, dispatch */ }, filter = null) {
    commit("setLoading", true);
    await axios
      .post(`${productsBackend}/filter/`, filter, config())
      .then(async (response) => {
        commit("setLoading", false);
        await commit("setFilters", response.data.filter);
        commit("setError", "");
      })
      .catch(async (error) => {
        if (error.response) {
          await commit("reset");
          commit("setError", error.response.data.message);
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
          console.log(error);
        }
      });
  },
  async addProduct({ commit /*, dispatch */ }, product) {
    commit("setLoading", true);
    await axios
      .post(`${productsBackend}/insert/`, product, config())
      .then(async () => {
        commit("setLoading", false);
        commit("setError", "");
        // dispatch(
        //   "notification/AddNotification",
        //   { type: "success", message: "Product Edited Successfully Created" },
        //   { root: true }
        // );
      })
      .catch((error) => {
        if (error.response.data.message) {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: error.message },
          //   { root: true }
          // );
        }
        commit("setLoading", false);
        commit("setError", error.data.message);
        throw error;
      });
  },
  // eslint-disable-next-line no-unused-vars
  async editProduct({ commit, dispatch }, product) {
    commit("setLoading", true);
    await axios
      .patch(`${productsBackend}/update/`, product, config())
      .then(async () => {
        commit("setLoading", false);
        commit("setError", "");
        // dispatch(
        //   "notification/AddNotification",
        //   { type: "success", message: "Product Edited Successfully Created" },
        //   { root: true }
        // );
      })
      .catch((error) => {
        if (error.response.data.message) {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: error.message },
          //   { root: true }
          // );
        }
        commit("setLoading", false);
        commit("setError", error.data.message);
        throw error;
      });
  },
  // eslint-disable-next-line no-unused-vars
  async deleteProduct({ commit, dispatch }, id) {
    commit("setLoading", true);
    await axios
      .delete(`${productsBackend}/delete/${id}/`, config())
      .then(async () => {
        commit("setLoading", false);
        commit("setError", "");
        // dispatch(
        //   "notification/AddNotification",
        //   { type: "success", message: "Product Edited Successfully Created" },
        //   { root: true }
        // );
      })
      .catch((error) => {
        if (error.response.data.message) {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: error.message },
          //   { root: true }
          // );
        }
        commit("setLoading", false);
        commit("setError", error.data.message);
        throw error;
      });
  },
  // eslint-disable-next-line no-unused-vars
  async deleteSelectedProducts({ commit, dispatch }, products) {
    commit("setLoading", true);
    await axios
      .post(`${productsBackend}/delete/`, products, config())
      .then(async () => {
        commit("setLoading", false);
        commit("setError", "");
        // dispatch(
        //   "notification/AddNotification",
        //   { type: "success", message: "Product Edited Successfully Created" },
        //   { root: true }
        // );
      })
      .catch((error) => {
        if (error.response.data.message) {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: error.message },
          //   { root: true }
          // );
        }
        commit("setLoading", false);
        commit("setError", error.data.message);
        throw error;
      });
  },
  // eslint-disable-next-line no-unused-vars
  async recoverProduct({ commit, dispatch }, id) {
    commit("setLoading", true);
    await axios
      .post(`${productsBackend}/recover/${id}/`, config())
      .then(async () => {
        commit("setLoading", false);
        commit("setError", "");
        // dispatch(
        //   "notification/AddNotification",
        //   { type: "success", message: "Product Edited Successfully Created" },
        //   { root: true }
        // );
      })
      .catch((error) => {
        if (error.response.data.message) {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: error.message },
          //   { root: true }
          // );
        }
        commit("setLoading", false);
        commit("setError", error.data.message);
        throw error;
      });
  },
  // eslint-disable-next-line no-unused-vars
  async recoverSelectedProducts({ commit, dispatch }, products) {
    commit("setLoading", true);
    await axios
      .post(`${productsBackend}/recover/`, products, config())
      .then(async () => {
        commit("setLoading", false);
        commit("setError", "");
        // dispatch(
        //   "notification/AddNotification",
        //   { type: "success", message: "Product Edited Successfully Created" },
        //   { root: true }
        // );
      })
      .catch((error) => {
        if (error.response.data.message) {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: error.message },
          //   { root: true }
          // );
        }
        commit("setLoading", false);
        commit("setError", error.data.message);
        throw error;
      });
  },
  async resetProducts({ commit }) {
    await commit("reset");
  },
};

const mutations = {
  reset(state) {
    state.product = {};
    state.barcode = "";
    state.products = [];
    state.loading = false;
    state.error = "";
    state.currentPage = 1;
    state.numPages = 1;
    state.totalRows = 0;
  },
  setProduct(state, response) {
    state.product = response;
  },
  setFilters(state, response) {
    state.filters = response;
  },
  setCategories(state, response) {
    state.categories = response.categories;
  },
  setProducts(state, response) {
    state.products = response.products;
    state.currentPage = response.currentPage;
    state.numPages = response.numPages;
    state.totalRows = response.totalRows;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setError(state, error) {
    state.error = error;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
