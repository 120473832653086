<template>
  <div class="autoComplete_wrapper" role="combobox" @focusout="onFocusOut">
    <div :class="{ 'search-box': icon !== '' }">
      <input
        ref="searchInput"
        :type="type"
        class="form-control"
        :placeholder="placeholder"
        @focusin="onFocusIn"
        v-model="value"
        @input="this.emitValue(-1)"
        :disabled="disabled"
      />
      <i v-if="icon !== ''" :class="icon"></i>
    </div>
    <ul :hidden="isHidden">
      <li
        @click="makeChoice(option.name, option.id)"
        :class="{ 'bg-soft-primary': value === option.name }"
        v-for="option in options"
        :key="option.id"
      >
        {{ option.name }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "AutoCompleteInput",
  props: {
    chosen: {
      default: "",
    },
    type: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onFocusOut() {
      setTimeout(() => {
        this.isHidden = true;
        if (this.chosenID == -1) {
          this.value = "";
        }
      }, 500);
    },
    onFocusIn() {
      this.isHidden = false;
      this.emitValue();
    },
    emitValue(id) {
      this.chosenID = id;
      this.$emit("choice", this.value, id);
    },
    makeChoice(option, id) {
      this.value = option;
      this.emitValue(id);
      this.isHidden = true;
    },
    setFocusToInput() {
      let input = this.$refs.searchInput;
      if (input) {
        input.focus();
      }
    },
  },
  data() {
    return {
      isHidden: true,
      chosenID: this.chosen.id,
      value: this.chosen.value,
    };
  },
  mounted() {
    if (this.autofocus) {
      this.setFocusToInput();
    }
  },
};
</script>

<style scoped></style>
<!--Can apply debouncing to decrease the api calls-->
