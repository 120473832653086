<template>
  <label for="companyAddress">Biller *</label>
  <AutoCompleteInput
    type="text"
    placeholder="Choose Biller"
    :chosen="biller"
    :options="billers"
    @choice="setBillerID"
    :disabled="status !== 'NEW'"
  />
</template>

<script>
import AutoCompleteInput from "@/components/utils/AutoCompleteInput";
import { SETTINGS } from "@/constants/config";
export default {
  name: "BillerInputGroup",
  components: { AutoCompleteInput },
  data() {
    return {
      biller: {},
    };
  },
  computed: {
    status() {
      return this.$store.getters.invoiceSummary.status;
    },
    billers() {
      return this.$store.getters.allBillers.map((item) => {
        return {
          ...item,
          name: item.company,
        };
      });
    },
  },
  methods: {
    async setBillerID(value, id) {
      let filter = {
        resultsPerPage: SETTINGS.resultsPerInput,
        searchQuery: value,
      };
      await this.$store.dispatch("getBillers", filter);
      this.biller.value = value;
      this.biller.id = id;
      if (id > 0) {
        await this.$store.dispatch("editInvoice", {
          id: this.$store.getters.invoiceSummary.id,
          billerID: id,
          type:
            this.$store.getters.invoiceSummary.type ||
            (id === 1 ? "cash" : "cheque"),
        });
        await this.$store.dispatch(
          "getInvoice",
          this.$store.getters.invoiceSummary.id
        );
        await this.$store.dispatch("getBiller", id);
      }
    },
  },
  async created() {
    // Initialize biller object
    if (!this.$store.getters.invoiceSummary.biller) {
      this.biller = { value: "", id: "" };
    } else {
      this.biller = {
        value: this.$store.getters.invoiceSummary.biller,
        id: this.$store.getters.invoiceSummary.billerID,
      };
    }

    // Retrieve billers based on conditions
    let filter = {
      resultsPerPage: SETTINGS.resultsPerInput,
    };
    await this.$store.dispatch("getBillers", filter);

    // If a biller ID exists, retrieve specific biller details
    if (this.biller.id && this.biller.id > 0) {
      await this.$store.dispatch("getBiller", this.biller.id);
    }
  },
};
</script>

<style scoped></style>
