<template>
  <div class="row align-items-center mb-4 gy-3">
    <div class="col-md-5">
      <p class="mb-0 text-muted">
        Showing <b>{{ rowsLoaded }}</b> of <b>{{ totalRows }}</b> results
      </p>
    </div>
    <div class="col-sm-auto ms-auto">
      <nav aria-label="...">
        <ul class="pagination mb-0">
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <button class="page-link" @click="goToPage(1)">&lt;&lt;</button>
          </li>
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <button class="page-link" @click="goToPage(currentPage - 1)">
              Previous
            </button>
          </li>
          <li
            class="page-item"
            :class="{ active: currentPage === page }"
            v-for="page in pagesFilter"
            :key="page"
          >
            <a class="page-link" @click="goToPage(page)">
              {{ page }}
            </a>
          </li>
          <li class="page-item" :class="{ disabled: currentPage === numPages }">
            <button class="page-link" @click="goToPage(currentPage + 1)">
              Next
            </button>
          </li>
          <li class="page-item" :class="{ disabled: currentPage === numPages }">
            <button class="page-link" @click="goToPage(numPages)">
              &gt;&gt;
            </button>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import { SETTINGS } from "@/constants/config";

export default {
  name: "paginationComponent",
  props: {
    rowsLoaded: {
      type: Number,
      default: 0,
    },
    currentPage: {
      type: Number,
      default: 0,
    },
    numPages: {
      type: Number,
      default: 0,
    },
    totalRows: {
      type: Number,
      default: 0,
    },
    goToPage: {
      type: Function,
      default: null,
    },
  },

  computed: {
    pagesFilter() {
      const totalPages = this.numPages;
      const displayPages = SETTINGS.pagesDisplayed;

      let startPage = Math.max(
        1,
        this.currentPage - Math.floor(displayPages / 2)
      );
      let endPage = Math.min(totalPages, startPage + displayPages - 1);

      if (endPage - startPage < displayPages - 1) {
        startPage = Math.max(1, endPage - displayPages + 1);
      }

      return Array.from(
        { length: endPage - startPage + 1 },
        (_, i) => startPage + i
      );
    },
  },
};
</script>

<style scoped></style>
