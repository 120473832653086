export const CONSTANTS = {
  RESULTS_PER_PAGE_OPTIONS: [10, 25, 50, 100, "ALL"],

  // User Related Constants
  USER_HEADERS: {
    email: "Email",
    username: "Name",
    type: "Company",
  },
  USER_FIELDS_UPDATE_ALLOWED: ["email", "username", "type"],

  // Price Group Related Constants
  PRICE_GROUP_HEADERS: {
    name: "Name",
  },
  PRICE_GROUP_FIELDS_UPDATE_ALLOWED: ["id", "name"],
  PRICE_GROUP_ITEM_HEADERS: {
    barcode: "Barcode",
    productName: "Product Name",
    costPrice: "Cost Price",
    sellingPrice: "Selling Price",
    updatedPrice: "Updated Price",
    suggestedRetailPrice: "Suggested Retail Price",
    updatedSuggestedRetailPrice: "Updated Suggested Retail Price",
  },
  PRICE_GROUP_ITEM_FIELDS_UPDATE_ALLOWED: ["price", "suggestedRetailPrice"],

  // Product Group Related Constants
  PRODUCT_GROUP_HEADERS: {
    name: "Name",
    costPrice: "Cost Price",
    sellingPrice: "Selling Price",
    suggestedRetailPrice: "Suggested Retail Price",
  },
  PRODUCT_GROUP_FIELDS_UPDATE_ALLOWED: [
    "name",
    "costPrice",
    "sellingPrice",
    "suggestedRetailPrice",
  ],
  PRODUCT_GROUP_ITEM_HEADERS: {
    barcode: "Barcode",
    name: "Product Name",
    costPrice: "Cost Price",
    sellingPrice: "Selling Price",
    suggestedRetailPrice: "Suggested Retail Price",
  },

  // Buyer Related Constants
  BUYER_HEADERS: {
    company: "Company",
    fullAddress: "Address",
    name: "Name",
    phone: "Phone",
    email: "Email",
    awardPoints: "Award Points",
    priceGroup: "Price Group",
  },
  BUYER_FIELDS_UPDATE_ALLOWED: [
    "company",
    "address",
    "city",
    "state",
    "postalCode",
    "country",
    "name",
    "phone",
    "email",
    "awardPoints",
    "priceGroupID",
  ],

  // Biller Related Constants
  BILLER_HEADERS: {
    company: "Company",
    fullAddress: "Address",
    phone: "Phone",
    email: "Email",
  },
  BILLER_FIELDS_UPDATE_ALLOWED: [
    "address",
    "city",
    "state",
    "postalCode",
    "country",
    "phone",
    "email",
  ],

  // Product Related Constants
  PRODUCTS_HEADERS: {
    barcode: "Barcode",
    name: "Name",
    category: "Category",
    boxBarcode: "Box Barcode",
    unitQuantity: "Unit Quantity",
    costPrice: "Cost Price",
    sellingPrice: "Selling Price",
    suggestedRetailPrice: "Suggested Retail Price",
    quantitySold: "Quantity Sold",
  },
  PRODUCTS_FIELDS_UPDATE_ALLOWED: [
    "name",
    "category",
    "costPrice",
    "sellingPrice",
    "suggestedRetailPrice",
    "quantitySold",
  ],

  // Invoice Related Constants
  INVOICE_HEADERS: {
    invoiceNo: "Invoice Number",
    date: "Date",
    biller: "Biller",
    customer: "Customer",
    total: "Total",
    paid: "Paid",
    discountRedeemed: "Discount",
    amountCredited: "Credit",
    balance: "Balance",
    status: "Status",
    remarks: "Remarks",
  },
  INVOICE_FIELDS_UPDATE_ALLOWED: [
    "date",
    "billerID",
    "customerID",
    "total",
    "paid",
    "status",
    "remarks",
  ],
  INVOICE_ITEM_HEADERS: {
    productName: "Product Name",
    barcode: "Barcode",
    sellingPrice: "Selling Price",
    quantity: "Quantity",
    subtotal: "Subtotal",
  },
  INVOICE_ITEM_FIELDS_UPDATE_ALLOWED: [
    "quantity",
    "sellingPrice",
    "suggestedRetailPrice",
    "costPrice",
    "damagedGood",
  ],
  DEFAULT_PAYMENT_HISTORY_FIELDS: {
    referenceNo: "Reference No",
    date: "Date",
    invoiceNo: "Invoice Number",
    biller: "Biller",
    customer: "Customer",
    total: "Amount Total",
    amountPaid: "Amount Paid",
    discountRedeemed: "Discount",
    amountDue: "Amount Due",
    paymentType: "Payment Type",
    chequeNo: "Cheque No",
    awardPointsRedeemed: "Award Points",
  },
  EXPENSES_HEADERS: {
    date: "Date",
    reference: "Reference",
    company: "Biller",
    expense: "Expense",
    category: "Category",
    amount: "Amount",
  },
};
