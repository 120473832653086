<template>
  <page-title pageTitle="Biller" title="New Biller" />
  <biller-form :biller="{}" :submitFunction="addBiller" />
</template>

<script>
import PageTitleComponent from "@/components/shared/PageTitle";
import BillerFormComponent from "@/components/biller/BillerForm";

export default {
  name: "NewBillerView",
  components: {
    PageTitle: PageTitleComponent,
    BillerForm: BillerFormComponent,
  },
  data() {
    return {
      id: -1,
      biller: null,
    };
  },
  methods: {
    async addBiller(biller) {
      await this.$store.dispatch("addBiller", biller);
      this.$router.push("/billers");
    },
  },
};
</script>

<style scoped></style>
