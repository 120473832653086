<template>
  <page-title pageTitle="Product" title="View Product" />
  <product-form v-if="!loading" :product="product" action-type="view" />
  <div v-else class="card">
    <LoadingModal v-if="loading" />
    <div v-else-if="error" class="card-body">
      {{ error }}
    </div>
    <div v-else class="card-body">The product doesn't exist</div>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/shared/PageTitle";
import ProductFormComponent from "@/components/product/ProductForm";
import LoadingModalComponent from "@/components/utils/LoadingModal";

export default {
  name: "ProductView",
  components: {
    PageTitle: PageTitleComponent,
    ProductForm: ProductFormComponent,
    LoadingModal: LoadingModalComponent,
  },
  data() {
    return {
      id: -1,
    };
  },
  computed: {
    product() {
      return this.$store.getters.product;
    },
    error() {
      return this.$store.getters.productsError;
    },
    loading() {
      return this.$store.getters.productsLoading;
    },
  },
  async created() {
    this.id = parseInt(this.$route.params.id);
    await this.$store.dispatch("getProduct", this.id);
  },
};
</script>

<style scoped></style>
