<template>
  <div
    class="app-menu navbar-menu"
    :class="{ 'show-mobile-nav': showMobileNav }"
  >
    <div class="navbar-brand-box">
      <router-link to="/" class="logo logo-light">
        <span class="logo-sm">
          <img src="/images/logo-light.png" alt="" height="22" />
        </span>
        <span class="logo-lg">
          <img src="/images/logo-light.png" alt="" height="50" />
        </span>
      </router-link>
      <router-link to="/" class="logo logo-dark">
        <span class="logo-sm">
          <img src="/images/logo-light.png" alt="" height="22" />
        </span>
        <span class="logo-lg">
          <img src="/images/logo-light.png" alt="" height="50" />
        </span>
      </router-link>
      <button
        type="button"
        class="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
        id="vertical-hover"
      >
        <i class="ri-record-circle-line"></i>
      </button>
    </div>

    <div id="scrollbar">
      <div class="container-fluid">
        <ul class="navbar-nav" id="navbar-nav">
          <li class="nav-item" v-for="(item, index) in menuItems" :key="index">
            <router-link
              v-if="typeof item === 'string'"
              class="nav-link menu-link"
              :data-bs-toggle="typeof item === 'object' ? 'collapse' : null"
              :to="item"
              @click="toggleIndex(index)"
            >
              <span>{{ index }}</span>
            </router-link>
            <a
              v-else
              class="nav-link menu-link"
              :data-bs-toggle="typeof item === 'object' ? 'collapse' : null"
              @click="toggleIndex(index)"
            >
              <span>{{ index }}</span>
            </a>
            <div
              v-if="typeof item === 'object'"
              class="collapse menu-dropdown"
              :class="{ show: toggleActive === index }"
            >
              <ul class="nav nav-sm flex-column">
                <li class="nav-item" v-for="(subItem, key) in item" :key="key">
                  <router-link
                    :to="subItem"
                    class="nav-link"
                    @click="onMobileNavToggle"
                  >
                    {{ key }}
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="sidebar-background"></div>
  </div>
  <div class="vertical-overlay"></div>
</template>

<script>
export default {
  name: "SidebarComponent",
  props: {
    menuItems: {
      type: [Object, null],
      default: null,
    },
  },
  data() {
    return {
      showMobileNav: false,
      toggleActive: -1,
    };
  },
  methods: {
    onMobileNavToggle() {
      var e = document.documentElement.clientWidth;
      767 < e &&
        document.querySelector(".hamburger-icon").classList.toggle("open"),
        "horizontal" === document.documentElement.getAttribute("data-layout") &&
          (document.body.classList.contains("menu")
            ? document.body.classList.remove("menu")
            : document.body.classList.add("menu")),
        "vertical" === document.documentElement.getAttribute("data-layout") &&
          (e < 1025 && 767 < e
            ? (document.body.classList.remove("vertical-sidebar-enable"),
              "sm" == document.documentElement.getAttribute("data-sidebar-size")
                ? document.documentElement.setAttribute("data-sidebar-size", "")
                : document.documentElement.setAttribute(
                    "data-sidebar-size",
                    "sm"
                  ))
            : 1025 < e
            ? (document.body.classList.remove("vertical-sidebar-enable"),
              "lg" == document.documentElement.getAttribute("data-sidebar-size")
                ? document.documentElement.setAttribute(
                    "data-sidebar-size",
                    "sm"
                  )
                : document.documentElement.setAttribute(
                    "data-sidebar-size",
                    "lg"
                  ))
            : e <= 767 &&
              (document.body.classList.add("vertical-sidebar-enable"),
              document.documentElement.setAttribute(
                "data-sidebar-size",
                "lg"
              )));
    },
    toggleIndex(index) {
      if (this.toggleActive === index) {
        this.toggleActive = -1;
      } else {
        this.toggleActive = index;
      }
    },
  },
  mounted() {
    this.eventBus.on("toggle-mobile-nav", this.onMobileNavToggle);
  },
  beforeUnmount() {
    this.eventBus.off("toggle-mobile-nav", this.onMobileNavToggle);
  },
};
</script>

<style scoped>
.navbar-menu::-webkit-scrollbar {
  width: 8px;
}
.navbar-menu::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
.navbar-menu::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}
.app-menu.show-mobile-nav {
  margin-left: 0;
}
</style>
