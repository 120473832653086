<template>
  <page-title
    pageTitle="Customer Price Group"
    title="New Customer Price Group"
  />
  <price-group-form :priceGroup="{}" :submitFunction="addPriceGroup" />
</template>

<script>
import PageTitleComponent from "@/components/shared/PageTitle";
import PriceGroupFormComponent from "@/components/priceGroup/PriceGroupForm";

export default {
  name: "NewPriceGroupView",
  components: {
    PageTitle: PageTitleComponent,
    PriceGroupForm: PriceGroupFormComponent,
  },
  data() {
    return {
      id: -1,
      priceGroup: null,
    };
  },
  methods: {
    async addPriceGroup(priceGroup) {
      await this.$store.dispatch("addPriceGroup", priceGroup);
      this.$router.push("/priceGroups");
    },
  },
};
</script>

<style scoped></style>
