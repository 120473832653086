import { createApp } from "vue";
import mitt from "mitt";
import App from "./App.vue";
import router from "./router";
import store from "./store";

const eventBus = mitt();
const app = createApp(App);
app.config.globalProperties.eventBus = eventBus;
app.use(store);
app.use(router);
app.mount("#app");
