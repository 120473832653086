<template>
  <sidebar-component :menuItems="menuItems" />
</template>

<script>
import SidebarComponent from "@/components/utils/Sidebar";
export default {
  name: "MenuComponent",
  components: { SidebarComponent },
  computed: {
    menuItems() {
      return {
        // Dashboard: "/",
        Users: {
          "List Users": "/users",
          "Add New User": "/users/new",
        },
        Products: {
          "List Products": "/products",
          "Add Products": "/products/new",
          "Print Barcode": "/products/barcode",
          "Recover Products": "/products/recover",
        },
        Invoices: {
          "List Invoices": "/invoices",
          "Add New Invoice": "/invoices/new",
        },
        "Customer Price Group": {
          "List Customer Price Groups": "/priceGroups",
          "Add New Customer Price Group": "/priceGroups/new",
        },
        "Product Price Group": {
          "List Product Price Groups": "/productGroups",
          "Add New Product Price Group": "/productGroups/new",
        },
        Billers: {
          "List Billers": "/billers",
          "Add New Biller": "/billers/new",
          "Recover Biller": "/billers/recover",
        },
        Customers: {
          "List Customers": "/customers",
          "Add New Customer": "/customers/new",
          "Recover Customer": "/customers/recover",
        },
        Reports: {
          "My Report": "/reports/customer",
          "Biller Report": "/reports/biller",
          "Product Report": "/reports/product",
          "Customer Report": "/reports/customerSummary",
          "Damaged Goods Report": "/reports/damagedGoods",
        },
        Expenses: {
          "List Expenses": "/expenses",
          "Add New Expense": "/expenses/new",
        },
      };
    },
  },
};
</script>

<style scoped></style>
