import axios from "axios";
import { CONFIG } from "@/constants/config.js";
import router from "@/router";

const productGroupsBackend = CONFIG.BACKEND_URL + "productGroup";

const state = {
  productGroupSummary: {},
  productGroups: [],
  productGroupItem: {},
  productGroup: [],
  loading: false,
  error: "",
  currentPage: 1,
  numPages: 1,
  totalRows: 0,
};

const config = () => ({
  headers: {
    Token: localStorage.getItem("accessToken"),
  },
});

const getters = {
  productGroupSummary: (state) => state.productGroupSummary,
  allProductGroups: (state) => state.productGroups,
  productGroup: (state) => state.productGroup,
  productGroupsLoading: (state) => state.loading,
  productGroupsError: (state) => state.error,
  productGroupsCurrentPage: (state) => state.currentPage,
  productGroupsNumPages: (state) => state.numPages,
  productGroupsTotalRows: (state) => state.totalRows,
  getProductGroupByID: (state) => (id) => {
    return state.productGroups.find((productGroup) => productGroup.id === id);
  },
};

const actions = {
  async getProductGroup({ commit /*, dispatch */ }, id) {
    commit("setLoading", true);
    await axios
      .post(`${productGroupsBackend}/summary/${id}/`, null, config())
      .then(async (response) => {
        commit("setLoading", false);
        await commit("setProductGroupSummary", response.data);
        commit("setError", "");
      })
      .catch(async (error) => {
        await router.push("/productGroups");
        if (error.response) {
          await commit("resetProductGroupSummary");
          alert(error.response.data.message);
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
        }
        console.log(error);
      });
  },
  async getProductGroups({ commit /*, dispatch */ }, filter = null) {
    commit("setLoading", true);
    await axios
      .post(`${productGroupsBackend}/all/`, filter, config())
      .then(async (response) => {
        commit("setLoading", false);
        await commit("setProductGroups", response.data);
        commit("setError", "");
      })
      .catch(async (error) => {
        if (error.response) {
          await commit("resetProductGroup");
          commit("setError", error.response.data.message);
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
          console.log(error);
        }
      });
  },
  async getProductGroupItems(
    { commit /*, dispatch */ },
    { id, filter = null }
  ) {
    commit("setLoading", true);
    console.log(filter);
    await axios
      .post(`${productGroupsBackend}/${id}/`, filter, config())
      .then(async (response) => {
        commit("setLoading", false);
        await commit("setProductGroup", response.data);
        commit("setError", "");
      })
      .catch(async (error) => {
        if (error.response) {
          await commit("resetProductGroup");
          commit("setError", error.response.data.message);
        } else {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: "There has been an error please try again another time." },
          //   { root: true }
          // );
          console.log(error);
        }
        commit("setLoading", false);
      });
  },
  async addProductGroup({ commit /*, dispatch */ }, productGroup) {
    commit("setLoading", true);
    await axios
      .post(`${productGroupsBackend}/new/`, productGroup, config())
      .then(async () => {
        commit("setLoading", false);
        commit("setError", "");
        // dispatch(
        //   "notification/AddNotification",
        //   { type: "success", message: "ProductGroup Edited Successfully Created" },
        //   { root: true }
        // );
      })
      .catch((error) => {
        if (error.response.data.message) {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: error.message },
          //   { root: true }
          // );
        }
        commit("setLoading", false);
        commit("setError", error.data.message);
      });
  },
  // eslint-disable-next-line no-unused-vars
  async editProductGroup({ commit, dispatch }, productGroup) {
    commit("setLoading", true);
    await axios
      .patch(`${productGroupsBackend}/update/`, productGroup, config())
      .then(async () => {
        commit("setLoading", false);
        commit("setError", "");
        // dispatch(
        //   "notification/AddNotification",
        //   { type: "success", message: "ProductGroup Edited Successfully Created" },
        //   { root: true }
        // );
      })
      .catch((error) => {
        if (error.response.data.message) {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: error.message },
          //   { root: true }
          // );
        }
        commit("setLoading", false);
        commit("setError", error.data.message);
      });
  },
  async addProductGroupItem({ commit /*, dispatch */ }, productGroupItem) {
    commit("setLoading", true);
    await axios
      .post(`${productGroupsBackend}/insert/`, productGroupItem, config())
      .then(async () => {
        commit("setLoading", false);
        commit("setError", "");
        // dispatch(
        //   "notification/AddNotification",
        //   { type: "success", message: "PriceGroup Edited Successfully Created" },
        //   { root: true }
        // );
      })
      .catch((error) => {
        if (error.response.data.message) {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: error.message },
          //   { root: true }
          // );
        }
        commit("setLoading", false);
        commit("setError", error.data.message);
      });
  },
  // eslint-disable-next-line no-unused-vars
  async deleteProductGroup({ commit, dispatch }, id) {
    commit("setLoading", true);
    await axios
      .delete(`${productGroupsBackend}/delete/${id}/`, config())
      .then(async () => {
        commit("setLoading", false);
        commit("setError", "");
        // dispatch(
        //   "notification/AddNotification",
        //   { type: "success", message: "ProductGroup Edited Successfully Created" },
        //   { root: true }
        // );
      })
      .catch((error) => {
        if (error.response.data.message) {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: error.message },
          //   { root: true }
          // );
        }
        commit("setLoading", false);
        commit("setError", error.data.message);
      });
  },
  // eslint-disable-next-line no-unused-vars
  async deleteProductGroupItem({ commit, dispatch }, id) {
    commit("setLoading", true);
    await axios
      .delete(`${productGroupsBackend}/delete/item/${id}/`, config())
      .then(async () => {
        commit("setLoading", false);
        commit("setError", "");
        // dispatch(
        //   "notification/AddNotification",
        //   { type: "success", message: "ProductGroup Edited Successfully Created" },
        //   { root: true }
        // );
      })
      .catch((error) => {
        if (error.response.data.message) {
          // dispatch(
          //   "notification/AddNotification",
          //   { type: "error", message: error.message },
          //   { root: true }
          // );
        }
        commit("setLoading", false);
        commit("setError", error.data.message);
      });
  },
};

const mutations = {
  resetProductGroupSummary(state) {
    state.productGroupSummary = {};
  },
  resetProductGroup(state) {
    state.productGroups = [];
    state.productGroupItem = {};
    state.productGroup = [];
    state.loading = false;
    state.error = "";
    state.currentPage = 1;
    state.numPages = 1;
    state.totalRows = 0;
  },
  setProductGroupSummary(state, response) {
    state.productGroupSummary = response;
  },
  setProductGroups(state, response) {
    state.productGroups = response.productGroups;
    state.currentPage = response.currentPage;
    state.numPages = response.numPages;
    state.totalRows = response.totalRows;
  },
  setProductGroup(state, response) {
    state.productGroup = response.products;
    state.currentPage = response.currentPage;
    state.numPages = response.numPages;
    state.totalRows = response.totalRows;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setError(state, error) {
    state.error = error;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
