<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div
        class="page-title-box d-sm-flex align-items-center justify-content-between"
      >
        <h4 class="mb-sm-0">{{ title }}</h4>

        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item">
              <a href="javascript: void(0);">{{ pageTitle }}</a>
            </li>
            <li class="breadcrumb-item active">{{ title }}</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
  <!-- end page title -->
</template>

<script>
export default {
  name: "PageTitleComponent",
  props: {
    title: String,
    pageTitle: String,
  },
};
</script>
