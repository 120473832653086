<template>
  <div class="table-responsive overflow-auto table-card">
    <table class="table table-nowrap align-middle mb-0">
      <thead class="table-dark opacity-85">
        <tr class="text-muted text-uppercase">
          <th v-if="deleteSelected" style="width: 50px">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                v-model="selectAll"
                @click="selectAllRows()"
              />
            </div>
          </th>
          <th scope="col">No</th>
          <th v-if="images" scope="col">Image</th>
          <th scope="col" v-for="(header, key) in headers" :key="key">
            {{ header }}
            &nbsp;
            <i
              :class="sortByClasses[dynamicSortingList[key]]"
              class="align-bottom me-1 clickable"
              @click="sortBy(key)"
            >
            </i>
          </th>
          <th v-if="actions" scope="col">Action</th>
          <th v-if="deleteSelected" scope="col">
            <i
              class="text-danger bi-trash3-fill align-bottom me-1 clickable"
              @click="deleteSelected(selectedRows)"
            ></i>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="(row, index) in data"
          :class="{ 'table-info': actionActive === index }"
          :key="index"
        >
          <td v-if="deleteSelected">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                v-model="row.selected"
              />
            </div>
          </td>
          <td>
            {{ offset ? offset + index + 1 : index + 1 }}
          </td>
          <td v-if="images">
            <div class="flex-shrink-0 me-3 avatar-sm">
              <div class="avatar-title bg-light rounded">
                <img :src="images[index]" alt="" class="avatar-xs" />
              </div>
            </div>
          </td>
          <td v-for="(header, key) in headers" :key="key">
            <p
              :class="{
                badge: key === 'status',
                'text-bg-danger': row[key] === 'DUE',
                'text-bg-primary': row[key] === 'PAID',
                'text-bg-warning': row[key] === 'REFUND',
              }"
            >
              {{ row[key] }}
            </p>
          </td>
          <td v-if="actions">
            <div class="dropdown">
              <button
                class="btn btn-soft-secondary btn-sm dropdown"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                @click="actionIndex(index)"
              >
                <i class="las la-ellipsis-h align-middle fs-18"></i>
              </button>
              <ul
                class="dropdown-menu dropdown-menu-end"
                :class="{ show: actionActive === index }"
              >
                <div v-for="(action, index) in actions" :key="index">
                  <li>
                    <a
                      class="dropdown-item"
                      @click="
                        action(row);
                        actionIndex(-1);
                      "
                    >
                      {{ index }}
                    </a>
                  </li>
                </div>
              </ul>
            </div>
          </td>
          <td v-if="deleteSelected"></td>
        </tr>
      </tbody>
      <!-- end tbody -->
      <tfoot v-if="footer" class="table-light">
        <tr class="fw-semibold">
          <td></td>
          <td v-for="(value, index) in footer" :key="index">
            {{ value }}
          </td>
        </tr>
      </tfoot>
    </table>
    <!-- end table -->
  </div>
  <!-- end table responsive -->
</template>

<script>
export default {
  name: "InvoicesTable",
  props: {
    headers: Object,
    footer: {
      type: [Array, null],
      default: null,
    },
    images: {
      type: [Array, null],
      default: null,
    },
    data: Array,
    actions: {
      type: [Object, null],
      default: null,
    },
    deleteSelected: {
      type: Function,
      default: null,
    },
    offset: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      actionActive: -1,
      selectAll: false,
      sortByClasses: [
        "ri-arrow-up-down-fill",
        "ri-arrow-up-fill",
        "ri-arrow-down-fill",
      ],
      sortingList: [],
    };
  },
  computed: {
    selectedRows() {
      return this.data.filter((item) => item.selected === true);
    },
    dynamicSortingList() {
      let list = {};
      for (const key in this.headers) {
        const index = this.sortingList.findIndex(
          (item) => Object.keys(item)[0] === key
        );
        if (index === -1) {
          list[key] = 0;
        } else {
          list[key] = this.sortingList[index][key];
        }
      }
      return list;
    },
  },
  methods: {
    actionIndex(index) {
      if (this.actionActive === index) {
        this.actionActive = -1;
      } else {
        this.actionActive = index;
      }
    },
    selectAllRows() {
      this.data.forEach((row) => {
        row.selected = !this.selectAll;
      });
    },
    sortBy(key) {
      const index = this.sortingList.findIndex(
        (item) => Object.keys(item)[0] === key
      );
      if (index === -1) {
        this.sortingList.push({ [key]: 1 });
      } else if (this.sortingList[index][key] === 1) {
        this.sortingList[index] = { [key]: 2 };
      } else {
        this.sortingList.splice(index, 1);
      }
      this.$emit("sort", 1, this.sortingList);
      console.log();
    },
  },
  watch: {
    selectedRows() {
      this.selectAll = this.selectedRows.length === this.data.length;
    },
  },
};
</script>

<style scoped>
.table-responsive {
  overflow: visible;
}
.clickable {
  cursor: pointer;
}
.dropdown-menu {
  position: relative;
  z-index: 1000;
}
</style>
