<template>
  <div>
    <div id="layout-wrapper" v-if="isAuthenticated">
      <vue-block v-if="!$route.fullPath.includes('print')">
        <div class="menu-component">
          <topbar />
          <menu-component />
        </div>
        <div class="main-content">
          <div class="page-content">
            <div class="container-fluid">
              <router-view />
            </div>
          </div>
          <footer-component />
        </div>
      </vue-block>
      <vue-block v-else>
        <router-view />
      </vue-block>
    </div>
    <div v-else>
      <router-view />
    </div>
  </div>
</template>

<script>
import FooterComponent from "@/components/shared/Footer";
import TopbarComponent from "@/components/shared/Topbar";
import MenuComponent from "@/components/shared/Menu";

export default {
  components: {
    Topbar: TopbarComponent,
    MenuComponent,
    FooterComponent,
  },
  computed: {
    isAuthenticated() {
      return !["signIn", "changePassword"].includes(this.$route.name);
    },
  },
  methods: {
    async handleStorageEvent(event) {
      if (event.key === "accessToken") {
        if (event.newValue === null) {
          await this.$store.dispatch("SignOut");
          this.$router.push("/signin");
        }
      }
    },
  },
  async created() {
    window.addEventListener("storage", this.handleStorageEvent);
  },
};
</script>

<style>
.form-control:disabled,
.disabled-form .form-control {
  pointer-events: none;
  opacity: 0.6;
}
.opacity-85 {
  opacity: 0.85 !important;
}
.table-responsive {
  overflow: visible;
}
@media print {
  .page-content {
    padding: 0 !important;
    margin: 0 !important;
  }
  @page {
    margin: 0;
  }
  body {
    margin: 0;
    padding: 0;
  }
}
</style>
