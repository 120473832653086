<template>
  <div v-if="invoiceItem.id">
    <div class="modal-backdrop fade show" bis_skin_checked="1"></div>
    <div
      class="modal fade show"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      bis_skin_checked="1"
      style="display: block; padding-left: 0px"
      aria-modal="true"
      role="dialog"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
        bis_skin_checked="1"
      >
        <div class="modal-content" bis_skin_checked="1">
          <div class="modal-header" bis_skin_checked="1">
            <h5 class="modal-title" id="myModalLabel">Edit Selling Price</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="closePopup"
            />
          </div>
          <div class="modal-body" bis_skin_checked="1">
            <div bis_skin_checked="1">
              <label for="sellingPrice" class="form-label">Product Name</label>
              <input
                type="text"
                class="form-control"
                id="sellingPrice"
                :value="invoiceItem.productName"
                readonly
              />
              <br />
            </div>
            <div bis_skin_checked="1">
              <label for="sellingPrice" class="form-label">Customer Name</label>
              <input
                type="text"
                class="form-control"
                id="sellingPrice"
                :value="invoiceItem.customer"
                readonly
              />
              <br />
            </div>
            <div class="row">
              <div bis_skin_checked="1" class="col">
                <label for="costPrice" class="form-label">Cost Price</label>
                <input
                  type="number"
                  step="0.01"
                  class="form-control"
                  id="costPrice"
                  v-model="invoiceItem.costPrice"
                  placeholder="Enter Cost Price"
                />
              </div>
              <div bis_skin_checked="1" class="col">
                <label for="sellingPrice" class="form-label"
                  >Selling Price</label
                >
                <input
                  type="number"
                  step="0.01"
                  class="form-control"
                  id="sellingPrice"
                  v-model="invoiceItem.sellingPrice"
                  placeholder="Enter Selling Price"
                />
                <br />
              </div>
            </div>
            <div
              class="simplebar-content"
              style="padding: 0px"
              bis_skin_checked="1"
            >
              <label class="form-label">Previous Selling Price</label>
              <ul
                v-if="previousSellingPrices.length > 0"
                class="list list-group list-group-flush mb-0"
              >
                <li
                  v-for="(transaction, index) in previousSellingPrices"
                  class="list-group-item"
                  :key="index"
                  data-id="1"
                >
                  <div class="d-flex" bis_skin_checked="1">
                    <div class="flex-grow-1" bis_skin_checked="1">
                      <h5>Invoice Date: {{ transaction.date }}</h5>
                      <p class="born timestamp text-muted mb-0">
                        Price: ${{ transaction.sellingPrice }} &nbsp; Quantity:
                        {{ transaction.quantity }}
                      </p>
                      <p class="born timestamp text-muted mb-0"></p>
                    </div>
                  </div>
                </li>
              </ul>
              <p v-else>Previous Selling Prices not available</p>
            </div>
          </div>
          <div class="modal-footer" bis_skin_checked="1">
            <button
              type="button"
              class="btn btn-light"
              data-bs-dismiss="modal"
              @click="closePopup"
            >
              Close
            </button>
            <button
              type="button"
              @click="updateInvoiceItem"
              class="btn btn-primary"
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <loading-modal v-else />
</template>

<script>
import LoadingModal from "@/components/utils/LoadingModal";
export default {
  name: "EditItemPriceModal",
  components: { LoadingModal },
  props: {
    productHistory: {
      type: Object,
      required: true,
    },
    closePopup: {
      type: Function,
      required: true,
    },
  },
  computed: {
    invoiceItem() {
      return this.productHistory.invoiceItem;
    },
    previousSellingPrices() {
      return this.productHistory.priceHistory;
    },
  },
  methods: {
    async updateInvoiceItem() {
      const updatedInvoiceItem = {
        id: this.invoiceItem.id,
        invoiceID: this.invoiceItem.invoiceID,
        quantity: this.invoiceItem.quantity,
        sellingPrice: parseFloat(this.invoiceItem.sellingPrice),
      };
      await this.$store.dispatch("editInvoiceItem", updatedInvoiceItem);
      await this.$store.dispatch("getInvoice", this.invoiceItem.invoiceID);
      await this.$store.dispatch("getInvoiceItems", {
        id: this.invoiceItem.invoiceID,
        filter: null,
      });
      this.closePopup();
    },
  },
};
</script>

<style scoped></style>
