<template>
  <div class="card px-3">
    <div class="card-header">
      <h4 class="card-title mb-0 text-center">Sign In</h4>
    </div>
    <div class="card-body">
      <form @submit.prevent="login" class="auth-input mt-4 pb-5">
        <div class="mb-3">
          <label for="username" class="form-label">Username</label>
          <input
            type="text"
            class="form-control"
            id="username"
            v-model="username"
            placeholder="Enter username"
          />
        </div>

        <div class="mb-2">
          <label for="userpassword" class="form-label">Password</label>
          <div class="position-relative auth-pass-inputgroup mb-3">
            <input
              :type="showPassword ? 'text' : 'password'"
              class="form-control pe-5 password-input"
              placeholder="Enter password"
              id="password-input"
              v-model="password"
            />
            <button
              class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
              type="button"
              id="password-addon"
              @click="showPassword = !showPassword"
            >
              <i class="las la-eye align-middle fs-18"></i>
            </button>
          </div>
        </div>

        <a
          href="/changePassword"
          class="text-muted text-decoration-underline fs-14"
        >
          Change password?
        </a>

        <div class="mt-3">
          <button class="btn btn-primary w-100" type="submit">Sign In</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "SignInView",
  data() {
    return {
      username: "",
      password: "",
      showPassword: false,
    };
  },
  methods: {
    async login() {
      const user = {};
      user.email = this.username;
      user.password = this.password;
      try {
        await this.$store.dispatch("SignIn", user);
        this.$router.push("/");
      } catch (error) {
        console.error(error.response.data.message);
      }
    },
  },
};
</script>

<style scoped>
.card {
  margin: 15vh 10vw;
}
</style>
