<template>
  <page-title pageTitle="Product" title="Edit Product" />
  <product-form
    v-if="!loading"
    :product="product"
    :submitFunction="editProduct"
    action-type="edit"
  />
  <div v-else className="card">
    <LoadingModal v-if="loading" />
    <div v-else-if="error" className="card-body">
      {{ error }}
    </div>
    <div v-else className="card-body">The product doesn't exist</div>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/shared/PageTitle";
import ProductFormComponent from "@/components/product/ProductForm";
import LoadingModalComponent from "@/components/utils/LoadingModal";

export default {
  name: "EditProductView",
  components: {
    PageTitle: PageTitleComponent,
    ProductForm: ProductFormComponent,
    LoadingModal: LoadingModalComponent,
  },
  data() {
    return {
      id: -1,
    };
  },
  computed: {
    product() {
      return this.$store.getters.product;
    },
    error() {
      return this.$store.getters.productsError;
    },
    loading() {
      return this.$store.getters.productsLoading;
    },
  },
  methods: {
    async editProduct(product) {
      await this.$store.dispatch("editProduct", product);
      this.$router.push("/products");
    },
  },
  async created() {
    this.id = parseInt(this.$route.params.id);
    await this.$store.dispatch("getProduct", this.id);
  },
};
</script>

<style scoped></style>
