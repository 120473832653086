<template>
  <div class="modal-backdrop fade show d-print-none" bis_skin_checked="1"></div>
  <div
    class="modal fade show d-print-none"
    id="staticBackdrop"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    bis_skin_checked="1"
    style="display: block; padding-left: 0px"
    aria-modal="true"
    role="dialog"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
      bis_skin_checked="1"
    >
      <div class="modal-content" bis_skin_checked="1">
        <div class="modal-header" bis_skin_checked="1">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="closePopup"
          />
        </div>
        <div class="modal-body text-center p-5 pt-3" bis_skin_checked="1">
          <div class="mt-4" bis_skin_checked="1">
            <h4 class="mb-3">{{ title }}</h4>
            <p class="text-muted mb-4">
              {{ message }}
            </p>
            <div
              class="hstack gap-2 justify-content-center"
              bis_skin_checked="1"
            >
              <button
                type="button"
                class="btn"
                :class="button.class"
                data-bs-dismiss="modal"
                v-for="(button, index) in buttons"
                @click="button.function()"
                :key="index"
              >
                {{ index }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DialogueModal",
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      default: "",
    },
    closePopup: {
      type: Function,
      required: true,
    },
    buttons: {
      type: [Object, null],
      default: null,
    },
  },
};
</script>

<style scoped></style>
