<template>
  <page-title pageTitle="User" title="View User" />
  <user-form v-if="!loading" :user="user" action-type="view" />
  <div v-else class="card">
    <LoadingModal v-if="loading" />
    <div v-else class="card-body">The user doesn't exist</div>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/shared/PageTitle";
import UserFormComponent from "@/components/users/UserForm";
import LoadingModalComponent from "@/components/utils/LoadingModal";

export default {
  name: "UserView",
  components: {
    PageTitle: PageTitleComponent,
    UserForm: UserFormComponent,
    LoadingModal: LoadingModalComponent,
  },
  data() {
    return {
      id: -1,
    };
  },
  computed: {
    user() {
      return this.$store.getters.person;
    },
    loading() {
      return !this.user.id;
    },
  },
  async created() {
    this.id = parseInt(this.$route.params.id);
    await this.$store.dispatch("getPerson", this.id);
  },
};
</script>

<style scoped></style>
