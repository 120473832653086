<template>
  <div class="row">
    <div class="col">
      <div class="card dash-mini">
        <div class="card-header border-0 align-items-center d-flex">
          <h4 class="card-title mb-0 flex-grow-1">This Week's Overview</h4>
          <div class="flex-shrink-0">
            <div class="dropdown card-header-dropdown">
              <a
                class="text-reset"
                href="#"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span class="fw-semibold text-uppercase fs-14">Trend: </span
                ><span class="text-muted"
                  >Current Week<i class="las la-angle-down fs-12 ms-2"></i
                ></span>
              </a>
              <div class="dropdown-menu dropdown-menu-end">
                <a class="dropdown-item" href="#">Today</a>
                <a class="dropdown-item" href="#">Last Week</a>
                <a class="dropdown-item" href="#">Last Month</a>
                <a class="dropdown-item" href="#">Current Year</a>
              </div>
            </div>
          </div>
        </div>
        <!-- end card header -->

        <div class="card-body pt-1">
          <div class="row">
            <div class="col-lg-4 mini-widget pb-3 pb-lg-0">
              <div class="d-flex align-items-end">
                <div class="flex-grow-1">
                  <h2 class="mb-0 fs-24">
                    <span class="counter-value" data-target="197">54</span>
                  </h2>
                  <h5 class="text-muted fs-16 mt-2 mb-0">Clients Added</h5>
                  <p class="text-muted mt-3 pt-1 mb-0 text-truncate">
                    <span class="badge bg-info me-1">1.15%</span> since last
                    week
                  </p>
                </div>
                <div class="flex-shrink-0 text-end dash-widget">
                  <div
                    id="mini-chart1"
                    data-colors='["--in-primary", "--in-light"]'
                    class="apex-charts"
                  ></div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 mini-widget py-3 py-lg-0">
              <div class="d-flex align-items-end">
                <div class="flex-grow-1">
                  <h2 class="mb-0 fs-24">
                    <span class="counter-value" data-target="634">124</span>
                  </h2>
                  <h5 class="text-muted fs-16 mt-2 mb-0">Contracts Signed</h5>
                  <p class="text-muted mt-3 pt-1 mb-0 text-truncate">
                    <span class="badge bg-danger me-1">1.15%</span> since last
                    week
                  </p>
                </div>
                <div class="flex-shrink-0 text-end dash-widget">
                  <div
                    id="mini-chart2"
                    data-colors='["--in-primary", "--in-light"]'
                    class="apex-charts"
                  ></div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 mini-widget pt-3 pt-lg-0">
              <div class="d-flex align-items-end">
                <div class="flex-grow-1">
                  <h2 class="mb-0 fs-24">
                    <span class="counter-value" data-target="512">214</span>
                  </h2>
                  <h5 class="text-muted fs-16 mt-2 mb-0">Invoice Sent</h5>
                  <p class="text-muted mt-3 pt-1 mb-0 text-truncate">
                    <span class="badge bg-info me-1">3.14%</span> since last
                    week
                  </p>
                </div>
                <div class="flex-shrink-0 text-end dash-widget">
                  <div
                    id="mini-chart3"
                    data-colors='["--in-primary", "--in-light"]'
                    class="apex-charts"
                  ></div>
                </div>
              </div>
            </div>
            <!-- end col -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</template>

<script>
export default {
  name: "OverviewPieCharts",
};
</script>

<style scoped></style>
