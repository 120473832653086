<template>
  <page-title pageTitle="Product" title="New Product" />
  <product-form :product="{}" :submitFunction="addProduct" action-type="new" />
</template>

<script>
import PageTitleComponent from "@/components/shared/PageTitle";
import ProductFormComponent from "@/components/product/ProductForm";

export default {
  name: "NewProductView",
  components: {
    PageTitle: PageTitleComponent,
    ProductForm: ProductFormComponent,
  },
  data() {
    return {
      id: -1,
      product: null,
    };
  },
  methods: {
    async addProduct(product) {
      await this.$store.dispatch("addProduct", product);
      this.$router.push("/products");
    },
  },
};
</script>

<style scoped></style>
