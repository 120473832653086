<template>
  <input
    ref="input"
    type="text"
    className="form-control flatpickr-input"
    placeholder="Select Date"
    @focus="showDatePicker"
    v-bind:value="formattedDate"
    @input="updateDate($event.target.value)"
  />
</template>

<script>
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";

export default {
  name: "DateInput",
  props: {
    modelValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      datePickerInstance: null,
    };
  },
  mounted() {
    this.initDatePicker();
  },
  methods: {
    initDatePicker() {
      this.datePickerInstance = flatpickr(this.$refs.input, {
        provider: "flatpickr",
        altInput: true,
        altFormat: "m-d-Y",
        dateFormat: "Y-m-d",
        onClose: this.closeDatePicker,
      });
    },
    showDatePicker() {
      if (this.datePickerInstance) {
        this.datePickerInstance.open();
      }
    },
    closeDatePicker(selectedDates, dateStr) {
      this.$emit("update:modelValue", dateStr);
    },
    updateDate(dateStr) {
      this.$emit("update:modelValue", dateStr);
    },
  },
  computed: {
    formattedDate() {
      return this.modelValue ? this.modelValue : "";
    },
  },
  watch: {
    modelValue(newVal) {
      if (this.datePickerInstance) {
        this.datePickerInstance.setDate(newVal, false);
      }
    },
  },
  beforeUnmount() {
    if (this.datePickerInstance) {
      this.datePickerInstance.destroy();
    }
  },
};
</script>

<style scoped>
/* Add any custom styling here */
</style>
