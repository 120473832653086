<template>
  <div class="row">
    <div class="col-xl-12">
      <div class="card">
        <div class="card-body">
          <div class="p-2">
            <form @submit.prevent="submit()">
              <div class="mb-3">
                <label for="username" class="form-label">Username</label>
                <input
                  type="text"
                  class="form-control"
                  id="username"
                  placeholder="Enter username"
                  v-model="updatedUser.username"
                  :disabled="disabled"
                />
              </div>

              <div class="mb-3">
                <label for="email" class="form-label">Email</label>
                <input
                  type="text"
                  class="form-control"
                  id="email"
                  placeholder="Enter email"
                  v-model="updatedUser.email"
                  :disabled="disabled"
                />
              </div>

              <div class="mb-3">
                <label for="type" class="form-label">Type</label>
                <select
                  type="text"
                  class="form-control"
                  id="type"
                  v-model="updatedUser.type"
                  :disabled="disabled"
                >
                  <option value="user">User</option>
                  <option value="admin">Admin</option>
                </select>
              </div>

              <div v-if="!disabled" class="mb-2">
                <label for="userpassword" class="form-label">Password</label>
                <div class="position-relative auth-pass-inputgroup mb-3">
                  <input
                    :type="showPassword ? 'text' : 'password'"
                    class="form-control pe-5 password-input"
                    placeholder="Enter password"
                    id="password-input"
                    v-model="updatedUser.password"
                  />
                  <button
                    class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                    type="button"
                    id="password-addon"
                    @click="showPassword = !showPassword"
                  >
                    <i class="las la-eye align-middle fs-18"></i>
                  </button>
                </div>
              </div>

              <div v-if="!disabled" class="mb-2">
                <label for="userpassword" class="form-label"
                  >Confirm Password</label
                >
                <div class="position-relative auth-pass-inputgroup mb-3">
                  <input
                    :type="showConfirmPassword ? 'text' : 'password'"
                    class="form-control pe-5 password-input"
                    placeholder="Enter confirm password"
                    id="password-input"
                  />
                  <button
                    class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                    type="button"
                    id="password-addon"
                    @click="showConfirmPassword = !showConfirmPassword"
                  >
                    <i class="las la-eye align-middle fs-18"></i>
                  </button>
                </div>
              </div>

              <div class="hstack gap-2 mt-4">
                <button v-if="!disabled" type="submit" class="btn btn-primary">
                  Save
                </button>
                <button
                  v-if="disabled"
                  type="button"
                  class="btn btn-primary"
                  @click="edit"
                >
                  Edit
                </button>
                <button type="button" class="btn btn-light" @click="discard">
                  Back
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserForm",
  props: {
    user: {
      type: Object,
      required: true,
    },
    actionType: {
      type: String,
      required: true,
    },
    submitFunction: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      showPassword: false,
      showConfirmPassword: false,
      updatedUser: {
        ...this.user,
        type: this.user.type ? this.user.type : "user",
      },
    };
  },
  methods: {
    submit() {
      if (!this.disabled) {
        this.submitFunction(this.updatedUser);
      }
    },
    discard() {
      this.updatedUser = { ...this.user };
      this.$router.push("/users/");
    },
    edit() {
      this.$router.push("/users/edit/" + this.user.id);
    },
  },
  computed: {
    disabled() {
      return typeof this.submitFunction !== "function";
    },
  },
  async beforeUnmount() {
    console.log("Person Destroyed");
    await this.$store.dispatch("resetPerson");
  },
};
</script>
